<template>
  <div
    class="modal fade"
    id="mdConfigSkill"
    tabindex="-1"
    aria-labelledby="mdConfigLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content bg-s p-1 p-sm-2 p-md-3 p-lg-4">
        <div class="cp mb-1 text-center">
          <h5>{{ $t("labels.settings") }}</h5>
        </div>
        <div class="d-flex justify-content-end d-md-none d-block">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="">
          <nav class="nav-tab-mobile">
            <div
              class="nav nav-tabs"
              ref="navTabConfigSkill"
              id="nav-tabConfigSkill"
              role="navTabConfigSkill"
            >
              <button
                ref="digitalConfig"
                @click="someMethod('digital')"
                class="nav-link firstBtnTab"
                id="nav-digitalConfig-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-digitalConfig"
                type="button"
                role="tab"
                aria-controls="nav-digitalConfig"
                aria-selected="false"
              >
                {{
                  $te("labels." + configs.digital_skills_title)
                    ? this.$t("labels." + configs.digital_skills_title)
                    : configs.digital_skills_title
                }}
              </button>
              <button
                ref="softConfig"
                @click="someMethod('soft')"
                class="nav-link"
                id="nav-softConfig-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-digitalConfig"
                type="button"
                role="tab"
                aria-controls="nav-digitalConfig"
                aria-selected="false"
              >
                {{
                  $te("labels." + configs.soft_skills_title)
                    ? this.$t("labels." + configs.soft_skills_title)
                    : configs.soft_skills_title
                }}
              </button>
              <button
                ref="languageConfig"
                @click="someMethod('language')"
                class="nav-link"
                id="nav-languageConfig-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-digitalConfig"
                type="button"
                role="tab"
                aria-controls="nav-digitalConfig"
                aria-selected="false"
              >
                {{
                  $te("labels." + configs.language_title)
                    ? this.$t("labels." + configs.language_title)
                    : configs.language_title
                }}
              </button>

              <button
                ref="OtherConfig"
                @click="someMethod('Other')"
                class="nav-link"
                id="nav-OtherConfig-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-OtherConfig"
                type="button"
                role="tab"
                aria-controls="nav-OtherConfig"
                aria-selected="false"
              >
                {{ $t("labels.others_configuration") }}
              </button>
            </div>
          </nav>
          <div
            ref="nav_tabContent_ConfigSkill"
            class="tab-content  p-1  p-md-2 p-lg-3 shadow mb-5 rounded bg-s0"
            id="nav-tabContent_ConfigSkill"
          >
            <div
              class="tab-pane fade"
              id="nav-OtherConfig"
              role="tabpanel"
              aria-labelledby="nav-OtherConfig-tab"
            >
              <div class="card mb-2">
                <div class="card-body">
                  <p class="strong mb-1">{{ $t("labels.change_titles") }}</p>
                  <div class="row g-2">
                    <div class="col-12 col-lg-6">
                      <OtherOpConfigTitle
                        :datas="[
                          'experience',
                          'work_experience',
                          'employment_history',
                        ]"
                        v-model="configs.experience_title"
                        title="title"
                      />
                    </div>
                    <div class="col-12 col-lg-6">
                      <OtherOpConfigTitle
                        :datas="['education', 'academic_qualification']"
                        v-model="configs.education_title"
                        title="title"
                      />
                    </div>
                    <div class="col-12 col-lg-6">
                      <OtherOpConfigTitle
                        :datas="[
                          'certification',
                          'certificates',
                          'certification_awards',
                        ]"
                        v-model="configs.certification_title"
                        title="title"
                      />
                    </div>
                    <div class="col-12 col-lg-6">
                      <OtherOpConfigTitle
                        :datas="['contact', 'contact_me']"
                        v-model="configs.contact_title"
                        title="title"
                      />
                    </div>
                    <div class="col-12 col-lg-6">
                      <OtherOpConfigTitle
                        :datas="['about_me', 'objective', 'profile']"
                        v-model="configs.about_me_title"
                        title="title"
                      />
                    </div>
                    <div class="col-12 col-lg-6">
                      <OtherOpConfigTitle
                        :datas="['hobbies', 'interests', 'hobbies_interests']"
                        v-model="configs.hobbies_title"
                        title="title"
                      />
                    </div>
                    <div class="col-12 col-lg-6">
                      <OtherOpConfigTitle
                        :datas="['more_info', 'other_info', 'personal_info']"
                        v-model="configs.more_info_title"
                        title="title"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade show active"
              id="nav-digitalConfig"
              role="tabpanel"
              aria-labelledby="nav-digitalConfig-tab"
            >
              <div v-if="!inArrConfigEvaluation" style="height: 300px"></div>
              <ConfigEvaluation
                v-else
                :config="configs"
                :configType="type"
                @saveSkillConfig="saveSkillConfig"
              />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
            @click="saveConfig"
            :disabled="loading"
            type="button"
            class="btn bg-p ms-2 mb-2"
          >
          <img class="me-2" src="@/assets/img/save.svg" alt="send">  {{ $t("buttons.save") }}
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import ConfigEvaluation from "@/components/config/ConfigEvaluation";
import OtherOpConfigTitle from "@/components/input/OtherOpConfigTitle";

export default {
  name: "ModalConfigs",
  data() {
    return {
      type: null,
      arrConfigEvaluation: ["digital", "soft", "language"],
      configs: Object.assign({}, this.modelValue),
    };
  },
  props: ["modelValue"],

  components: { ConfigEvaluation, OtherOpConfigTitle },
  computed: {
    inArrConfigEvaluation: function() {
      return this.arrConfigEvaluation.includes(this.type);
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler: function(newValue) {
        this.configs = Object.assign({}, newValue);
      },
    },
  },
  mounted() {},

  methods: {
    show(type) {
      this.type = type;
      this.mdConfig = new Modal(document.getElementById("mdConfigSkill"));
      this.configs = Object.assign({}, this.modelValue);
      this.mdConfig.show();
      document.getElementById("nav-" + type + "Config-tab").click();
    },
    someMethod(type) {
      this.type = type;
      this.scroolActiveNavTav(type);

      setTimeout(() => {
        // wait opem modal
        this.scroolActiveNavTav(type);
      }, 800);
    },

    scroolActiveNavTav(type) {
      //Set Scrooll Possition
      const nav_tabContent = this.$refs.nav_tabContent_ConfigSkill.getBoundingClientRect()
        .left;
      let left =
        this.$refs[type + "Config"].getBoundingClientRect().left +
        this.$refs.navTabConfigSkill.scrollLeft -
        nav_tabContent;
      //button type size
      let buttonWidth = this.$refs[type + "Config"].offsetWidth / 2;
      //window
      left =
        left -
        this.$refs.nav_tabContent_ConfigSkill.offsetWidth / 2 +
        buttonWidth;

      //set scroll position
      this.$refs.navTabConfigSkill.scrollLeft = left;

      //End Scrooll Possition
    },
    saveConfig() {
      this.$emit("update:modelValue", this.configs);
      this.$emit("saveConfig");
      this.mdConfig.hide();
    },

    saveSkillConfig(config, type) {
      /*   console.log(config,type)*/
      switch (type) {
        case "digital":
          this.configs.digital_skills_tipo_display = config.type_display;
          this.configs.digital_skills_tipo = config.skills_tipo;
          this.configs.digital_skills_title = config.skills_title;
          //this.configs.digital_skills_active_color = config.active_color;
          //this.configs.digital_skills_inactive_color = config.inactive_color;
          break;
        case "soft":
          this.configs.soft_skills_tipo_display = config.type_display;
          this.configs.soft_skills_tipo = config.skills_tipo;
          this.configs.soft_skills_title = config.skills_title;
          //this.configs.soft_skills_active_color = config.active_color;
          //this.configs.soft_skills_inactive_color = config.inactive_color;
          break;

        case "language":
          this.configs.language_tipo_display = config.type_display;
          this.configs.language_tipo = config.skills_tipo;
          this.configs.language_title = config.skills_title;
          //this.configs.language_active_color = config.active_color;
          //this.configs.language_inactive_color = config.inactive_color;
          break;

        default:
          return;
      }
    },
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 700px;
}

.btn-close {
  position: absolute;
  top: 15px;
  left: 90%;
}
</style>
