<template>
  <div v-if="tipo == 0 && tipo_display == 1">
    <span v-for="skill in datas" :key="skill.name" class="badge">{{
      $t("templeteText." + skill.name)
    }}</span>
  </div>
  <div v-else>
    <div
      v-for="skill in datas"
      :key="skill.name"
      :class="{
        'd-flex justify-content-between': tipo_display == 1,
      }"
    >
      <p class="mt-1">{{ $t("templeteText." + skill.name) }}</p>

      <p class="mt-1" v-if="tipo == 2 || tipo == 4">
        <svg
          width="22"
          height="3"
          class="svgRetangleEvaluation"
          :class="{
            svgRetangleEvaluationLeft: tipo_display != 1,
           
          }"
        >
          <rect
            :class="{
              svg_inactive: tipo == 2,
              svg_inactiveBorder: tipo != 2,
              svg_rect_rx: tipo == 2,
            }"
            x="0"
            y="0"
            :rx="[tipo == 2 ? 2 : 0]"
            width="100%"
            height="3"
            :fill_old="inactiveColor"
            :stroke="[tipo == 2 ? '' : '']"
            :strokeWidth="[tipo == 2 ? 0 : 2]"
          />
          <rect
          :class="{
           
           svg_rect_rx: tipo == 2,
          }"
            x="0"
            y="0"
            :rx="[tipo == 2 ? 2 : 0]"
            width="67%"
            height="3"
            class="svg_active"
            :fill_old="activeColor"
          />
        </svg>
      </p>

      <p class="mt-1" v-else-if="tipo == 1 || tipo == 3">
        <star
          class="svgStarEvaluation"
          :tp="tipo"
          v-for="i in 3"
          :key="i"
          :class="{
            svgStarEvaluationLeft: tipo_display != 1,
          }"
          svg_status="svg_active"
          :color="activeColor"
        />
        <star
          class="svgStarEvaluation"
          :tp="tipo"
          v-for="i in 2"
          :key="i"
          :class="{
            svgStarEvaluationLeft: tipo_display != 1,
          }"
          svg_status="svg_inactive"
          :color="inactiveColor"
        />
      </p>
    </div>
  </div>
</template>

<script type="text/javascript">
import star from "@/components/ico/star";
export default {
  name: "Evaluation",
  components: {
    star,
  },
  props: {
    datas: {
      default: [],
    },
    tipo_display: {
      default: 0,
    },
    tipo: { default: 0 },
    inactiveColor: { default: "" },
    activeColor: { default: "" },
  },

  data() {
    return {
      starPoints: [19.8, 2.2, 6.6, 43.56, 39.6, 17.16, 0, 17.16, 33, 43.56],
    };
  },
  computed: {
    starPointsToString() {
      return this.starPoints.join(",");
    },
  },
  created() {},
  methods: {},
};
</script>

<style scoped>
.vue-star-rating-star {
  overflow: visible !important;
}

.vue-star-rating-star-rotate {
  transition: all 0.25s;
}

.vue-star-rating-star-rotate:hover {
  transition: transform 0.25s;
  transform: rotate(-15deg) scale(1.3);
}
</style>
