<template>
  <div class="d-flex justify-content-end">
    <button @click="add" type="button" class="btn ms-2 btn-sm btnIcon">
      <img src="@/assets/img/plus.svg" alt="" />
    </button>
  </div>
</template>
<script>
export default {
  name: "NavAddRemoveBtn",

  data: function () {
    return {};
  },
  methods: {
    add() {
      this.$emit("add");
    },
  },
};
</script>