<template>
  <div class="text-center cp mb-2">
    <h5>{{ $t("labels.image_crop") }}</h5>
  </div>
  <div class="d-flex justify-content-end">
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
    ></button>
  </div>
  <div class="d-flex justify-content-end mb-2">
    <button
      :disabled="loading"
      type="button"
      class="btn btn-primary"
      @click="cropImage"
    >
      {{ $t("buttons.save") }}
      <span
        v-if="loading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </button>
  </div>
  <div class="">
    <Alert :alerts="alert"></Alert>
  </div>
  <div>
    <div @click="openinputFile()" class="bottom-right">
      {{ $t("buttons.changue_picture") }}
    </div>
    <vue-cropper
      v-show="imgSrc == null ? false : true"
      ref="cropper"
      viewMode="3"
      :aspectRatio="1 / 1"
      :initialAspectRatio="1 / 1"
      :src="imgSrc"
      :movable="true"
      :crop-box-movable="true"
      :crop-box-resizable="TRUE"
      :guides="false"
      :dragMode="move"
      :autoCropArea="0.92"
    />
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import api from "@/services/api";
import Alert from "@/components/alert/Alert";

export default {
  components: {
    VueCropper,
    Alert,
  },
  props: ["curriculumVersionID"],
  data() {
    return {
      imgSrc: null,
      loading: false,
      alert: {
        msg: "",
        type: "",
      },
    };
  },
  methods: {
    openinputFile() {
      this.$emit("openinputFile");
    },
    cropImage() {
      /*
     let cropImg = this.$refs.cropper
        .getCroppedCanvas({
          //  width: 200,
          //  height: 200
        })
        .toDataURL();
*/
      this.$refs.cropper.getCroppedCanvas({}).toBlob((blob) => {
        const formData = new FormData();
        formData.append("file", blob, "avatar.png");
        formData.append("id", this.curriculumVersionID);
        this.saveUserImage(formData);
      });
    },
    saveUserImage(formData) {
      this.loading = true;
      api
        .post("/me/update/image", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (!response.data.error) {
            this.loading = false;

            this.$emit("saveUserImage", response.data.data.photoUrl);
          } else {
            throw new Error(response.data.messages);
          }
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        });
    },

    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bottom-right {
  z-index: 9;

  top: 93px;
  right: 13px;
}
.btn-close {
  position: absolute;
  top: 15px;
  left: 90%;
}
</style>
