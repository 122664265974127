<template>
  <div>
  <div class="d-flex justify-content-end mb-2">
      <button
        @click="$emit('openModalConfigs', 'digital')"
        type="button"
        class="btn ms-2 btn-sm btnIcon"
      >
        <img src="@/assets/img/config.svg" alt="" />
      </button>
    </div>
    <Container
      @drop="onDrop($event)"
      non-drag-area-selector=".form-control"
      drag-class="form-ghost"
      drop-class="form-ghost-drop"
      :class="{ isActive: true }"
    >
      <Draggable
      v-for="(digitalSkill, index) in digitalSkills"
      :key="digitalSkill"
      :index="index"
      >
    <DigitalSkills
    :digitalSkills="digitalSkills"
      :config="config"
 
      :key="digitalSkill"
      :index="index"
      :userDigitalSkill="digitalSkill"
        @remove="removeDigitalSkill"
    :length="digitalSkills.length"
    >
    </DigitalSkills>
  </Draggable>
    </Container>
   <NavAddRemoveBtn
    @add="addDigitalSkill"
  />
    
  </div>
</template>
    
    <script>
import DigitalSkills from "@/components/subComponrnts/DigitalSkills";

import NavAddRemoveBtn from "@/components/subComponrnts/NavAddRemoveBtn";

import { Container, Draggable } from "vue-dndrop";

export default {
  name: "CardDigitalSkills",
  data() {
    return {
      
    };
  },

  props: ["config", "digitalSkills"],
  components: {
    Draggable,
    Container,
    DigitalSkills,
 
    NavAddRemoveBtn,
  },

  methods: {
    onDrop(dropResult) {
      this.$emit("onDropDigitalSkill", dropResult);
    },
    addDigitalSkill() {
      this.$emit("addDigitalSkill");
    },
    removeDigitalSkill(index) {
      this.$emit("removeDigitalSkill",index);
    },
  },
};
</script>
