<!-- v-if="userData" -->
<main v-if="userData">
  <HorizontalAdst
    v-if="(!userData.email.includes('@themycv') && userData.email !='youremailseuemail@gmail.com' ) && adsActive === 'true'"
  >
  </HorizontalAdst>
  <div class="container">
    <div class="mt-4 mb-3 text-center">
      <h3>{{ $t("labels.cvBuilder1") }} {{ $t("labels.cvBuilder3") }}</h3>
    </div>
    <div class="card_version_preview justify-content-between mb-3">
      <div class="row">
        <div class="col">
          <SelectAddRemove
            class="mb-0"
            MaxCaracter="30"
            keyName="version_name"
            v-model="curriculumVersion"
            title="version"
            :datas="user.curriculums"
            placeholder="labels.version_name"
            cancelDefaultAddOptionEvent="true"
            @addNewOption="addNewOptionCurriculum"
            @removeIten="confirmeRemoveOptionCurriculum"
          />
        </div>
        <div class="col-auto d-flex align-items-center p-0">
          <div class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle p-1"
              href="#"
              id="navbarDropdownMenuLink"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                :src="require('@/assets/img/flag_' + this.$i18n.locale
                + '.svg')"
                class="flag-ico"
                :alt="'flag ' + this.$i18n.locale
                "
              />
            </a>
            <ul
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li v-for="language in listLanguages" :key="language.key">
                <a
                  class="dropdown-item"
                  href="#"
                  @click="changeLanguage(language.key)"
                  :class="{ active: this.$i18n.locale === language.key }"
                >
                  <img
                    :src="
                          require('@/assets/img/flag_' + language.key + '.svg')
                        "
                    class="flag-ico"
                    :alt="'flag ' + language.key"
                  />
                  {{ $t(language.label) }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="d-flex align-items-center mt-3 mt-md-0">
        <button
          style="    margin-left: auto!important;"
          :disabled="loading"
          type="button"
          @click="save"
          class="btn bg-p ms-2"
        >
        <img class="me-2" src="@/assets/img/file-text.svg" alt="send"> {{ $t("buttons.preview") }}<span
            v-if="loading"
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <form>
          <div class="">
            <Alert :alerts="alert"></Alert>
          </div>
          <div class="shadow p-3 mb-5 bg-white rounded">
            <div class="row mb-2 g-3 d-flex align-items-center">
              <div class="col-md-6 col-lg-3 col-xl-3 col-xxl-2 divImg">
                <div @click="openinputFile()" class="bottom-right">
                  {{ $t("buttons.changue_picture") }}
                </div>
                <div
                  v-if="isLoadingProfileImage"
                  class="rounded placeholder-glow"
                  style="width: 100%"
                >
                  <span
                    class="placeholder col-12 placeholder img"
                    style="width: 100%; padding-top: 96%; position: relative"
                  ></span>
                </div>
                <img
                  v-show="!isLoadingProfileImage"
                  @load="loadedProfileImage"
                  :src="userPhotoUrl"
                  class="img-fluid userImg"
                  alt="eu"
                  @error="
                        this.userData.photoUrl = require('@/assets/img/default-user.jpg')
                      "
                />

                <input
                  ref="fileInput"
                  type="file"
                  @input="loadModalCrop"
                  @click="resetImageUploader"
                  accept="image/png, image/jpeg"
                  style="display: none"
                />
              </div>

              <div class="col-md-6 col-lg-9 col-xxl-10">
                <div class="row">
                  <div class="col-12">
                    <div class="form-floating mb-3">
                      <input
                        v-model="userData.full_name"
                        :class="{ 'is-invalid': !full_nameValid }"
                        type="text"
                        class="form-control"
                        placeholder=" "
                      />
                      <label
                        >{{ $t("labels.full_name") }}<span class="r"
                          >*</span
                        ></label
                      >
                    </div>
                  </div>

                  <div class="col-12 col-lg-8">
                    <div class="form-floating mb-3">
                      <input
                        readonly
                        v-model="userData.email"
                        :class="{ 'is-invalid': !emailValide }"
                        type="email"
                        class="form-control"
                        placeholder=" "
                      />
                      <label
                        >{{ $t("labels.email_address") }}<span class="r"
                          >*</span
                        ></label
                      >
                    </div>
                  </div>

                  <div class="col-12 col-lg-4 mb-3">
                    <AutoComplete
                      v-model="userData.profession"
                      title="profession"
                      :datas="occupations"
                    />
                  </div>
                  <div class="col-md-6 col-lg-4">
                    <Phone
                      v-model:phoneNumber="userData.phone"
                      keyName="dial_code"
                      v-model:dialCode="userData.dialCode"
                      title="phone"
                      :countries="pageData.countries"
                    />
                  </div>

                  <div class="col-md-6 col-lg-4">
                    <div class="form-floating mb-3">
                      <input
                        v-model="userData.address"
                        type="text"
                        class="form-control"
                        placeholder=" "
                      />
                      <label for="floatingInput"
                        >{{ $t("labels.address") }}</label
                      >
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-floating mb-3">
                      <input
                        v-model="userData.city"
                        type="text"
                        class="form-control"
                        placeholder=" "
                      />
                      <label for="floatingInput">{{ $t("labels.city") }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    style="height: 80px"
                    v-model="userData.aboutMe"
                    class="form-control"
                    placeholder=" "
                  ></textarea>
                  <label for="floatingTextarea2"
                    >{{ $t("labels.about_me") }}</label
                  >
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-12">
        <div class="">
          <nav class="nav-tab-mobile">
            <div class="nav nav-tabs" ref="navTab" id="nav-tab" role="tablist">
              <button
                ref="Experience"
                @click="someMethod('Experience')"
                class="nav-link active firstBtnTab"
                id="nav-experience-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-experience"
                type="button"
                role="tab"
                aria-controls="nav-experience"
                aria-selected="true"
              >
                {{ $te( "labels." +
                user.curriculums[this.curriculumVersion].config
                .experience_title ) ? this.$t( "labels." +
                user.curriculums[this.curriculumVersion].config
                .experience_title ) :
                user.curriculums[this.curriculumVersion].config
                .experience_title }}
              </button>
              <button
                ref="Education"
                @click="someMethod('Education')"
                class="nav-link"
                id="nav-education-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-education"
                type="button"
                role="tab"
                aria-controls="nav-education"
                aria-selected="true"
              >
                {{ $te( "labels." +
                user.curriculums[this.curriculumVersion].config .education_title
                ) ? this.$t( "labels." +
                user.curriculums[this.curriculumVersion].config .education_title
                ) : user.curriculums[this.curriculumVersion].config
                .education_title }}
              </button>

              <button
                ref="Certification"
                @click="someMethod('Certification')"
                class="nav-link"
                id="nav-certification-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-certification"
                type="button"
                role="tab"
                aria-controls="nav-certification"
                aria-selected="true"
              >
                {{ $te( "labels." +
                user.curriculums[this.curriculumVersion].config
                .certification_title ) ? this.$t( "labels." +
                user.curriculums[this.curriculumVersion].config
                .certification_title ) :
                user.curriculums[this.curriculumVersion].config
                .certification_title }}
              </button>

              <button
                ref="Digital"
                @click="someMethod('Digital')"
                class="nav-link"
                id="nav-digitalSkill-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-digitalSkill"
                type="button"
                role="tab"
                aria-controls="nav-digitalSkill"
                aria-selected="false"
              >
                {{ $te( "labels." +
                user.curriculums[this.curriculumVersion].config
                .digital_skills_title ) ? this.$t( "labels." +
                user.curriculums[this.curriculumVersion].config
                .digital_skills_title ) :
                user.curriculums[this.curriculumVersion].config
                .digital_skills_title }}
              </button>
              <button
                ref="Soft"
                @click="someMethod('Soft')"
                class="nav-link"
                id="nav-softSkill-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-softSkill"
                type="button"
                role="tab"
                aria-controls="nav-softSkill"
                aria-selected="false"
              >
                {{ $te( "labels." +
                user.curriculums[this.curriculumVersion].config
                .soft_skills_title ) ? this.$t( "labels." +
                user.curriculums[this.curriculumVersion].config
                .soft_skills_title ) :
                user.curriculums[this.curriculumVersion].config
                .soft_skills_title }}
              </button>

              <button
                ref="Language"
                @click="someMethod('Language')"
                class="nav-link"
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
              >
                {{ $te( "labels." +
                user.curriculums[this.curriculumVersion].config .language_title
                ) ? this.$t( "labels." +
                user.curriculums[this.curriculumVersion].config .language_title
                ) : user.curriculums[this.curriculumVersion].config
                .language_title }}
              </button>

              <button
                ref="Other"
                @click="someMethod('Other')"
                class="nav-link"
                id="nav-contact-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-contact"
                type="button"
                role="tab"
                aria-controls="nav-contact"
                aria-selected="false"
              >
                {{ $t("labels.other_info") }}
              </button>
            </div>
          </nav>
          <div
            ref="nav_tabContent"
            class="tab-content p-3 shadow mb-5 rounded bg-s0"
            id="nav-tabContent"
          >
            <div
              class="tab-pane fade show active"
              id="nav-experience"
              role="tabpanel"
              aria-labelledby="nav-experience-tab"
            >
              <CardExperience
                :userExperience="userData.experiences"
                :occupations="occupations"
                @addExperience="addExperience"
                @onDropExperience="onDropExperience"
                @removeExperience="removeExperience"
                @openModalConfigs="openModalConfigs"
              ></CardExperience>
            </div>

            <div
              class="tab-pane fade"
              id="nav-education"
              role="tabpanel"
              aria-labelledby="nav-education-tab"
            >
              <CardEducation
                v-if="activeNavsTabs.includes('Education')"
                :userEducation="userData.educations"
                @addEducation="addEducation"
                @onDropEducation="onDropEducation"
                @removeEducation="removeEducation"
                @openModalConfigs="openModalConfigs"
              ></CardEducation>
            </div>
            <div
              class="tab-pane fade"
              id="nav-certification"
              role="tabpanel"
              aria-labelledby="nav-certification-tab"
            >
              <CardCertification
                v-if="activeNavsTabs.includes('Certification')"
                :userCertification="userData.certifications"
                @addCertification="addCertification"
                @onDropCertification="onDropCertification"
                @removeCertification="removeCertification"
                @openModalConfigs="openModalConfigs"
              ></CardCertification>
            </div>

            <div
              class="tab-pane fade"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <CardLanguage
                v-if="activeNavsTabs.includes('Language')"
                :config="userData.config"
                :userLanguage="userData.languages"
                @onDropLanguage="onDropLanguage"
                @addLanguage="addLanguage"
                @removeLanguage="removeLanguage"
                @openModalConfigs="openModalConfigs"
              ></CardLanguage>
            </div>

            <div
              class="tab-pane fade"
              id="nav-softSkill"
              role="tabpanel"
              aria-labelledby="nav-softSkill-tab"
            >
              <CardSoftSkills
                v-if="activeNavsTabs.includes('Soft')"
                :softSkills="userData.softSkills"
                :config="userData.config"
                @onDropSoftSkill="onDropSoftSkill"
                @addSoftSkill="addSoftSkill"
                @removeSoftSkill="removeSoftSkill"
                @openModalConfigs="openModalConfigs"
              >
              </CardSoftSkills>
            </div>

            <div
              class="tab-pane fade"
              id="nav-digitalSkill"
              role="tabpanel"
              aria-labelledby="nav-digitalSkill-tab"
            >
              <CardDigitalSkills
                v-if="activeNavsTabs.includes('Digital')"
                :digitalSkills="userData.digitalSkills"
                :config="user.curriculums[this.curriculumVersion].config"
                @onDropDigitalSkill="onDropDigitalSkill"
                @addDigitalSkill="addDigitalSkill"
                @removeDigitalSkill="removeDigitalSkill"
                @openModalConfigs="openModalConfigs"
              >
              </CardDigitalSkills>
            </div>

            <div
              class="tab-pane fade"
              id="nav-contact"
              role="tabpanel"
              aria-labelledby="nav-contact-tab"
            >
              <CardOther
                v-if="activeNavsTabs.includes('Other')"
                @updateOtherInfo="updateOtherInfo"
                @openModalConfigs="openModalConfigs"
                :curriculumVersionID="curriculumVersionID"
                :userDatas="userData"
                :pageData="pageData"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--##############                  Modal            ###########################33-->
  <div
    class="modal fade"
    id="mdModel"
    tabindex="-1"
    aria-labelledby="mdModelLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div
        v-if="activeModalPreview"
        class="modal-content p-3 bg-s"
        style="max-height: 100%; overflow: hidden"
      >
        <div class="row">
          <h5 class="text-center cp mb-2">{{ $t("labels.templates") }}</h5>
          <div class="d-flex justify-content-end d-md-none d-block">
            <button
              type="button"
              class="btn-close btn-closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <!--
              <div
                class="col-12 d-flex justify-content-end align-items-center mb-0"
              >
                <button
                  :disabled="loading"
                  type="button"
                  @click="loadPreview()"
                  class="btn bg-p"
                >
                  {{ $t("buttons.view") }}
                </button>
              </div>
  -->
          <div class="col-12">
            <nav class="nav-tab-mobile">
              <div
                class="nav nav-tabs"
                ref="navTab2"
                id="nav-tab2"
                role="tablist"
              >
                <button
                  ref="navPersonalize"
                  class="nav-link active"
                  id="nav-personalize-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-personalize"
                  type="button"
                  role="tab"
                  aria-controls="nav-personalize"
                  aria-selected="true"
                >
                  {{ $t("labels.customization") }}
                </button>

                <button
                  ref="navTemplate"
                  class="nav-link"
                  id="nav-template-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-template"
                  type="button"
                  role="tab"
                  aria-controls="nav-template"
                  aria-selected="true"
                >
                  {{ $t("labels.templates") }}
                </button>
              </div>
            </nav>

            <div
              ref="nav_tabContent2"
              class="tab-content p-3 pt-2 shadow mb-5 rounded bg-s0"
              id="nav-tabContent2"
            >
              <div
                class="tab-pane fade show active"
                id="nav-personalize"
                role="tabpanel"
                aria-labelledby="nav-personalize-tab"
              >
                <div class="row divColorConfig pt-0">
                  <div class="col-12">
                    <div class="d-flex justify-content-end mb-0 mt-0">
                      <button
                        :disabled="loading"
                        type="button"
                        @click="loadPreview()"
                        class="btn btn-sm bg-p"
                      >
                      <img class="me-2" src="@/assets/img/pdf-white.svg" alt="send">   {{ $t("buttons.view") }}
                      </button>
                    </div>
                  </div>
                  <div class="col">
                    <T1
                      @listExperienceClick="listExperienceClick"
                      @openModalConfigs="openModalConfigs"
                      :configColor="
                            user.curriculums[this.curriculumVersion].config
                          "
                      active="true"
                      class="p-1 template-zom"
                    />
                  </div>
                  <div class="col-12 col-lg">
                    <div class="mb-0 mt-2 me-2">
                      <a
                        @click.prevent="randomConfig"
                        href="#"
                        class="link-primary mb-1 mt-0 cp1"
                      >
                        <img
                          style="width: 14px"
                          src="@/assets/img/random.svg"
                          class="me-1"
                        />
                        {{ $t("labels.random_template") }}
                      </a>
                    </div>
                    <div class="card mb-2 mt-2">
                      <div class="card-body card-body-setting">
                        <p class="setingTitle">{{ $t("labels.layout") }}</p>
                        <div class="col-auto p-3 pb-0 pt-0 row">
                          <div :key="i" v-for="i in 12" class="col-auto p-1">
                            <Layout
                              @click="
                                    selectItem(
                                      templateOption[i - 1].template,
                                      templateOption[i - 1].positionStyle,
                                      templateOption[i - 1].modeloStyle,
                                      templateOption[i - 1].style,
                                      templateOption[i - 1].navPadding
                                    )
                                  "
                              :class="{
                                    active:
                                      selectedTemplate ==
                                      'T' +
                                        templateOption[i - 1].template +
                                        templateOption[i - 1].positionStyle +
                                        templateOption[i - 1].modeloStyle +
                                        templateOption[i - 1].style +
                                        templateOption[i - 1].navPadding,
                                  }"
                              :model="i"
                              class="icoLayout"
                              style="width: 26px"
                            >
                            </Layout>
                          </div>
                        </div>

                        <div class="row mb-2 mt-2 p-0 col-12">
                          <div class="col-3 pe-0">
                            <input
                              v-model="userData.config.border_radius"
                              type="range"
                              class="form-range"
                              min="0"
                              max="6"
                              id="customRange2"
                            />
                          </div>
                          <label
                            for="colFormLabelSm"
                            class="
                                  col-9 col-form-label col-form-label-sm
                                  ps-2
                                "
                            >{{ $t("labels.radius") }}</label
                          >
                        </div>

                        <div class="row mb-2 mt-2 p-0 col-12">
                          <div class="col-3 pe-0">
                            <input
                              v-model="userData.config.hr_width"
                              type="range"
                              class="form-range"
                              min="0"
                              max="100"
                              id="customRange3"
                            />
                          </div>
                          <label
                            for="colFormLabelSm"
                            class="
                                  col-9 col-form-label col-form-label-sm
                                  ps-2
                                "
                            >{{ $t("labels.horizontal_separator") }}</label
                          >
                        </div>

                        <div class="form-floating">
                          <select
                            class="form-select"
                            v-model="userData.config.photo"
                            aria-label="Floating label select example"
                          >
                            <option value="0">
                              {{ $t("labels.normal") }}
                            </option>
                            <option value="1">
                              {{ $t("labels.rounded_photo") }}
                            </option>
                            <option value="2">
                              {{ $t("labels.no_photo") }}
                            </option>
                          </select>
                          <label for="floatingSelect">
                            {{ $t("labels.photo") }}</label
                          >
                        </div>
                      </div>
                    </div>

                    <div class="card mb-2 mt-2">
                      <div class="card-body card-body-setting">
                        <p class="setingTitle">Card 1</p>

                        <p class="">
                          <Color v-model="userData.config.p_color" />
                          {{ $t("labels.title_color") }}
                        </p>
                        <p class="">
                          <Color v-model="userData.config.s_color" />
                          {{ $t("labels.text_color") }}
                        </p>
                        <p>
                          <Color v-model="userData.config.p_bg" />
                          {{ $t("labels.background_color") }}
                        </p>
                      </div>
                    </div>

                    <div class="card mb-2 mt-2">
                      <div class="card-body card-body-setting">
                        <p class="setingTitle">Card 2</p>

                        <p class="">
                          <Color v-model="userData.config.nav2TextColor1" />
                          {{ $t("labels.title_color") }}
                        </p>
                        <p class="">
                          <Color v-model="userData.config.nav2TextColor2" />
                          {{ $t("labels.text_color") }}
                        </p>
                        <p>
                          <Color v-model="userData.config.s_bg" />
                          {{ $t("labels.background_color") }}
                        </p>
                      </div>
                    </div>

                    <div class="card mb-2 mt-2 card-btn">
                      <div
                        @click="openModalConfigs('Other')"
                        class="card-body card-body-btn"
                      >
                        <img src="@/assets/img/config.svg" alt="" />
                        {{ $t("labels.others_configuration") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="tab-pane fade"
                id="nav-template"
                role="tabpanel"
                aria-labelledby="nav-template-tab"
              >
                <div class="row g-3 pt-2">
                  <CardImage
                    @view="view"
                    @personalize="personalize"
                    class="card-img-t col-sm-6 col-md-6 col-lg-4"
                    v-for="n in templateOrder"
                    :key="n"
                    :n="n"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal  mdModalPicture -->
  <div
    class="modal fade"
    id="mdModalPicture"
    aria-labelledby="mdModalPictureLabel"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <ImageCrop
            @openinputFile="openinputFile"
            @saveUserImage="saveUserImage"
            :curriculumVersionID="curriculumVersionID"
            ref="childComponent"
          />
        </div>
      </div>
    </div>
  </div>

  <NewCurriculumVersionOption
    @changeLanguage="changeLanguage"
    @addNewOption="addNewOptionCurriculum"
    :occupations="occupations"
    :firstTime_openModal_create_new_version="firstTime_openModal_create_new_version"
  />
  <ViewCV :urlPreview="urlPreview" />
  <ModalConfigs
    ref="ModalConfigs"
    v-model="user.curriculums[this.curriculumVersion].config"
    @saveConfig="saveConfig"
  />
</main>
