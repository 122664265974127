<template>
  <div class="d-flex justify-content-center mt-1 mb-0" id="advertisingDiv">
    <span class="small">{{ $t("labels.advertising") }} </span>
  </div>
  <div class="d-flex justify-content-center mt-0">
    <!-- Composite Start -->
    <div id="M900946ScriptRootC1498024"></div>
    <!-- Composite End -->
  </div>
</template>
<script>
export default {
  name: "HorizontalAdst",
  data() {
    return {};
  },

  mounted() {

    if ( window.location.href.toLowerCase().includes('themycv.com')) this.addADS();
  },

  methods: {
    addADS() {
      let scr = document.createElement("script");
      scr.innerHTML = `
    scr = document.createElement("script");
  
      scr.src =
      "https://jsc.adskeeper.com/t/h/themycv.com.1498024.js";
      document.querySelector("#advertisingDiv").appendChild(scr);
    `;
      document.querySelector("#advertisingDiv").appendChild(scr);
    },
  },
};
</script>
<style scoped>
.small {
  font-size: 0.6em;
}
</style>
