<template>
  <svg viewBox="0 0 595 842">
    <rect
      width="595"
      height="842"
      style="fill: #8f8d8d; stroke-width: 0; stroke: #8f8d8d"
    />
    <rect
      :y="nav1Y"
      :x="nav1X"
      :width="nav1W"
      :height="nav1H"
      style="fill: #3c3c3c"
    />

    <ellipse :fill="circleColor"  :cy="circleY" :cx="circleX" rx="66" ry="66" />

    Sorry, your browser does not support inline SVG.
  </svg>
</template>

<script>
export default {
  name: "Layout",

  props: {
    model: {
      default: "1",
    },
  },
  data() {
    return {
      circleY:170,
      circleX: 140,
      nav1H: 280,
      nav1W: 595,

      nav1X: 0,
      nav1Y: 0,
      nav1SW: 0,

      circleColor: "#8f8d8d",
    };
  },
  mounted() {
    switch (this.model) {
      case 1:
        this.circleX = 115;
        this.nav1H = 842;
        this.nav1W = 240;

        break;
      case 2:
        this.circleX = 190;
        this.nav1H = 700;
        this.nav1W = 240;
        this.nav1X = 70;
        this.nav1Y = 70;
        break;

      case 3:
        this.circleX = 140;

        break;
      case 4:
        (this.nav1H = 220), (this.circleX = 120);

        this.nav1W = 540;

        this.nav1X = 0;
        this.nav1Y = 70;

        break;

      case 5:
        this.circleColor = "#3c3c3c";
        this.circleX = 90;
        this.nav1H = 700;
        this.nav1W = 355;
        this.nav1X = 190;
        this.nav1Y = 70;
        break;

      case 6:
        this.circleColor = "#3c3c3c";
          this.circleY = "150";
        this.circleX = 120;
        this.nav1Y = 240;
        this.nav1W = 540;
        this.nav1H = 542;
        break;

      case 7:
        this.circleX = 455;
        this.nav1H = 842;
        this.nav1W = 240;
        this.nav1X = 355;

        break;

      case 8:
        this.circleX = 420;
        this.nav1H = 700;
        this.nav1W = 240;
        this.nav1X = 295;
        this.nav1Y = 70;
        break;

      case 9:
        this.circleX = 455;

        break;
      case 10:
        (this.nav1H = 220), (this.circleX = 455);

        this.nav1W = 540;

        this.nav1X = 70;
        this.nav1Y = 70;

        break;

      case 11:
        this.circleColor = "#3c3c3c";
        this.circleX = 520;
        this.nav1H = 700;
        this.nav1W = 355;
        this.nav1X = 70;
        this.nav1Y = 70;
        break;

      case 12:
        this.circleColor = "#3c3c3c";
        this.circleY = "150";
        this.circleX = 455;
        this.nav1Y = 240;
        this.nav1W = 560;
        this.nav1H = 542;
        this.nav1X = 70;
        break;
    }
  },
};
</script>

<style scoped>
.my-svg-component {
  /* Scoped CSS here */
}
</style>