<template>
  <div>
    <div class="d-flex justify-content-end mb-2">
      <button
        @click="$emit('openModalConfigs', 'Other')"
        type="button"
        class="btn ms-2 btn-sm btnIcon"
      >
        <img src="@/assets/img/config.svg" alt="" />
      </button>
    </div>
    <Container
      @drop="onDrop($event)"
      non-drag-area-selector=".form-control"
      drag-class="form-ghost"
      drop-class="form-ghost-drop"
      :class="{ isActive: true }"
    >
      <Draggable
        v-for="(certification, index) in userCertification"
        :index="index"
        :key="certification"
      >
        <Certification
          :index="index"
          :key="certification"
          :length="userCertification.length"
          @remove="removeCertification"
          :userCertification="certification"
        ></Certification>
      </Draggable>
    </Container>
    <NavAddRemoveBtn @add="addCertification" />
  </div>
</template>

<script>
import NavAddRemoveBtn from "@/components/subComponrnts/NavAddRemoveBtn";
import Certification from "@/components/subComponrnts/Certification";
import { Container, Draggable } from "vue-dndrop";

export default {
  name: "CardCertification",
  props: ["userCertification"],
  components: {
    Draggable,
    Container,
    Certification,
    NavAddRemoveBtn,
  },
  data: function() {
    return {
      certification: this.userCertification,
    };
  },

  methods: {
    onDrop(dropResult) {
      this.$emit("onDropCertification", dropResult);
    },

    addCertification() {
      this.$emit("addCertification");
    },

    removeCertification(index) {
      this.$emit("removeCertification", index);
    },
  },
};
</script>
