<template>
  <div>
    <div class="d-flex justify-content-end mb-2">
      <button
        @click="$emit('openModalConfigs', 'Other')"
        type="button"
        class="btn ms-2 btn-sm btnIcon"
      >
        <img src="@/assets/img/config.svg" alt="" />
      </button>
    </div>
    <Container
      @drop="onDrop($event)"
      non-drag-area-selector=".form-control"
      drag-class="form-ghost"
      drop-class="form-ghost-drop"
      :class="{ isActive: true }"
    >
      <Draggable
        v-for="(experience, index) in userExperience"
        :key="experience"
        :index="index"
      >
        <Experience
          :key="experience"
          :index="index"
          :userExperience="experience"
          :occupations="occupations"
          :length="userExperience.length"
          @remove="removeExperience"
        ></Experience>
      </Draggable>
    </Container>
    <NavAddRemoveBtn @add="addExperience" />
  </div>
</template>
<script>
import NavAddRemoveBtn from "@/components/subComponrnts/NavAddRemoveBtn";
import Experience from "@/components/subComponrnts/Experience";
import { Container, Draggable } from "vue-dndrop";
export default {
  name: "CardExperience",
  components: {
    Draggable,
    Container,
    Experience,
    NavAddRemoveBtn,
  },
  props: ["userExperience", "occupations"],
  data: function() {
    return {};
  },

  methods: {
    onDrop(dropResult) {
      this.$emit("onDropExperience", dropResult);
    },
    addExperience() {
      this.$emit("addExperience");
    },
    removeExperience(index) {
      this.$emit("removeExperience", index);
    },
  },
};
</script>
