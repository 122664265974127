<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row g-2">
        <div class="col-md-6 col-lg-5 col-xl-4">
          <AutoComplete
            v-model="language.language"
            title="language_skill"
            :datas="languages[dataLang]"
            :exists="dataArr"
          />
        </div>

        <div
          class="
            col-md-6 col-lg-4 col-xl-3
            ps-2
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <star-rating
            v-if="config.language_tipo == 1"
            :inactive-color="config.language_inactive_color"
            :active-color="config.language_active_color"
            v-model:rating="language.level"
            :increment="1"
            :show-rating="false"
          ></star-rating>
          <star-rating
            tp="2"
            v-else-if="config.language_tipo == 3"
            :inactive-color="config.language_inactive_color"
            :active-color="config.language_active_color"
            v-model:rating="language.level"
            :increment="1"
            :show-rating="false"
          ></star-rating>

          <vue3-slider
            class="vue3-slider-2"
            v-else-if="config.language_tipo == 4"
            height="30"
            max="5"
            v-model="language.level"
            :color="config.language_active_color"
            :track-color="config.language_inactive_color"
          />
          <vue3-slider
            v-else-if="config.language_tipo == 2"
            height="30"
            max="5"
            v-model="language.level"
            :color="config.language_active_color"
            :track-color="config.language_inactive_color"
          />
              <star-rating
            v-else
            readOnly="true"
  
            rating="0"
            :increment="1"
            :show-rating="false"
          ></star-rating>
          <img
            v-if="length > 1"
            @click="remove"
            class="btnIconRemove ms-3"
            src="@/assets/img/delete.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarRating from "@/components/star/star-rating";
import slider from "vue3-slider";
import AutoComplete from "@/components/input/AutoComplete";
import languagesJson from "@/data/languages.json";
export default {
  name: "CardLanguage",
  components: {
    AutoComplete,
    StarRating,
    "vue3-slider": slider,
  },
  props: ["config", "userLanguage", "length", "index", "userLanguages"],
  data: function () {
    return {
      languages: languagesJson,
      language: this.userLanguage,
    };
  },
  computed: {
    dataArr: function () {
      return this.userLanguages.map((d) => d.language);
    },
    dataLang: function () {
      if (this.$i18n.locale == "pt_PT") return "pt_BR";
      return this.$i18n.locale;
    },
  },
  methods: {
    remove() {
      this.$emit("remove", this.index);
    },
  },
};
</script>