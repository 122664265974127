
<template>
  <div
    class="t1-p1-Top"
    style="
      color: var(--navTopTextColor);
      padding: var(--navPaddingTop);
      margin: 0px;
     z-index: 0;
         position: relative;
    "
  >
    <div   
      class="row"
      style="
      
        padding: 0px;
        margin: 0px;
        border-radius: var(--border_radius);
         height: 60px;
      "
    >
      <div v-if="configColor.photo != 2"
        :class="{ 'order-2': configColor.positionStyle == 1 ? false : true }"
        class="t1-p1-nav-Top"
        style=" width: 60px
        padding: 10px;
        "
      >
        <img
      
          src="@/assets/img/default-user.jpg"
          class="imgUSer imgUSerTop" 
          :class="[ configColor.photo ==1 ? 'circle' : ''  ]"
          alt="eu"
        />
      </div>

      <div
        :class="{ paddingRightZerro: configColor.positionStyle == 1 ? false : true }"
        class="t1-p2-nav-top d-flex align-items-center"
        style="padding: 4px"
         :style="{ width: col2Zise }"
      >
        <div>
          <h1 class="mt-2">Michelle Sophie M Martins</h1>
          <p class="strong mt-1">{{ $t("templeteText.administrative_manager") }}</p>
          <div class="row">
            <div
              class="col"
              style="color: var(--navTopTextColor1); padding-right: 0px"
            >
              <p class="mt-2 tj" style="color: var(--navTopTextColor1)">
                is simply dummy text of the printing and typesetting industry.
                the 1500s, when took a galley of type.
              </p>
            </div>
            <div class="qrcod col-auto" style="padding-left: 1px">
              <qrcode
                textColor="--navTopTextColor"
                bgColor="--navTopBgColor"
                style="height: 15px; width:15px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
 <div
    class="t1-Top"
    style="
       z-index: -1;
     background-color: var(--navTopBgColor);
    border-radius: var(--border_radius);
    position: absolute;
    top: 0px;
    left: 0px;
    width: var(--navTopWidth);
    height: var(--navTopHeight);
    margin: var(--navTopMargin); 
     
    "
  >
     </div>
  </div>
</template>
<script>
import qrcode from "@/components/ico/qrcode";
export default {
  components: {
    qrcode,
  },
  name: "NavTop",

  props: ["configColor"],
  data() {
    return {};
  },
  computed: {

  col2Zise: function () {
      if (this.configColor.photo == 2 ) return '100%';
     return '120px'
     
    },
  },
 
  methods: {},
};
</script>

<style scoped>
.noPhoto{

}
.imgUSerTop{
  margin-top: 8px;
    width: 42px;
    height: 42px;
    padding: 0px;
}
</style>


