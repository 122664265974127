
<template>
  <div class="t1-p1">
    <div>
      <div v-if="configColor.modeloStyle == 1">
        <img
          v-if="configColor.photo != 2"
          src="@/assets/img/default-user.jpg"
          class="img-fluid imgUSer"
          :class="[configColor.photo == 1 ? 'circle' : '']"
          alt="eu"
        /> 
        <br v-if="configColor.photo == 2" />
        <h1 class="tc mt-1">Michelle Sophie</h1>
        <p class="tc strong mt-1">
          {{ $t("templeteText.administrative_manager") }}
        </p>
        <br />
        <div v-if="configColor.photo == 2">
          <br />
          <h1 @click="$emit('openModalConfigs', 'Other')" class="cl">
            {{
              $te("labels." + configColor.contact_title)
                ? this.$t("labels." + configColor.contact_title)
                : configColor.contact_title
            }}
          </h1>
          <hr />
        </div>
      </div>
      <div class="" v-else>
        <h1 @click="$emit('openModalConfigs', 'Other')" class="cl">
          {{
            $te("labels." + configColor.contact_title)
              ? this.$t("labels." + configColor.contact_title)
              : configColor.contact_title
          }}
        </h1>
        <hr />
      </div>
      <div @click="$emit('openModalConfigs', 'Other')" class="cl">
        <p class="mt-1">
          <span class="icoEmail ico_info"> </span> email@themycv.com
        </p>
        <p class="mt-1">
          <span class="icoFone ico_info"> </span> +238 123 456 789
        </p>

        <p class="mt-1">
          <span class="icoLinkedIn ico_info"></span>@company/themycv
        </p>
        <p class="mt-1"><span class="icoHome ico_info"></span> ThemyCV</p>
        <!--
      <p class="mt-1"><span class="icoUser ico_info"></span> feminine</p>
      <p class="mt-1"><span class="icoCalender ico_info"></span> 1996-10-25</p>
-->
      </div>
      <h1 class="mt-5 cl" @click="$emit('openModalConfigs', 'digital')">
        {{
          $te("labels." + configColor.digital_skills_title)
            ? this.$t("labels." + configColor.digital_skills_title)
            : configColor.digital_skills_title
        }}
      </h1>
      <hr />

      <Evaluation
        class="cl"
        @click="$emit('openModalConfigs', 'digital')"
        :activeColor="configColor.digital_skills_active_color"
        :inactiveColor="configColor.digital_skills_inactive_color"
        :tipo_display="configColor.digital_skills_tipo_display"
        :tipo="configColor.digital_skills_tipo"
        :datas="digitalSkills.slice(0, limitDigital)"
      >
      </Evaluation>

      <h1 class="mt-5 cl" @click="$emit('openModalConfigs', 'language')">
        {{
          $te("labels." + configColor.language_title)
            ? this.$t("labels." + configColor.language_title)
            : configColor.language_title
        }}
      </h1>
      <hr />
      <Evaluation
        class="cl"
        @click="$emit('openModalConfigs', 'language')"
        :activeColor="configColor.language_active_color"
        :inactiveColor="configColor.language_inactive_color"
        :tipo_display="configColor.language_tipo_display"
        :tipo="configColor.language_tipo"
        :datas="language"
      >
      </Evaluation>

      <h1 @click="$emit('openModalConfigs', 'Other')" class="mt-5 cl">
        {{
          $te("labels." + configColor.more_info_title)
            ? this.$t("labels." + configColor.more_info_title)
            : configColor.more_info_title
        }}
      </h1>
      <hr />
      <div @click="$emit('openModalConfigs', 'Other')" class="cl">
        <div class="d-flex justify-content-between">
          <p class="mt-1">{{ $t("labels.gender") }}</p>
          <p class="mt-1">{{ $t("templeteText.feminine") }}</p>
        </div>
        <div class="d-flex justify-content-between">
          <p class="mt-1">{{ $t("labels.driving_licence") }}</p>
          <p class="mt-1">B</p>
        </div>
      </div>

      <h1 @click="$emit('openModalConfigs', 'Other')" class="mt-5 cl">
        {{
          $te("labels." + configColor.hobbies_title)
            ? this.$t("labels." + configColor.hobbies_title)
            : configColor.hobbies_title
        }}
      </h1>
      <hr />
      <div>
        <div @click="$emit('openModalConfigs', 'Other')" class="cl">
          <span v-for="skill in hobbies" :key="skill.name" class="badge">{{
            $t("templeteText." + skill.name)
          }}</span>
        </div>
      </div>
    </div>
    <div class="t1-p1-nav"></div>
  </div>
</template>
<script>
import Evaluation from "@/components/templates/T1/parts/Evaluation";
export default {
  name: "Nav1",
  components: {
    Evaluation,
  },
  props: ["configColor"],
  data() {
    return {
      language: [{ name: "english" }, { name: "portuguese" }],

      digitalSkills: [
        { name: "word" },
        { name: "excel" },
        { name: "photoshop" },
        { name: "cloud_networking" },
        { name: "HTML_CSS" },
      ],
      hobbies: [
        { name: "dance" },
        { name: "baking" },
        { name: "blogging" },
        { name: "entertaining" },
        { name: "hiking" },
      ],
    };
  },
  computed: {
    limitDigital: function () {
      if (this.configColor.digital_skills_tipo_display != 1) return 2;
      return 5;
    },
  },
  methods: {},
};
</script>
 

