<template>
  <div class="form-floating ">
    <input
      @focusout="focusout"
      @focus="show = true"
       :value="textValue"
       @input="(evt) => (textValue = evt.target.value)"
    
      type="text"
      class="form-control"
      autocomplete="off"
      placeholder=" "
    />

    <transition name="fade">
      <ul
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        v-if="show"
        class="list-unstyled ulAuto"
      >
        <li
          v-for="countrie in filtredCountries"
          :key="countrie"
          @click="selectOp(countrie)"
          class="list-unstyled sgID liAuto"
        >
          {{ countrie }}
        </li>
      </ul>
    </transition>

    <label for="floatingSelectGrid">{{ $t('labels.' + title) }}</label>
  </div>
</template>

<script>
export default {
  name: "AutoComplete",
  data() {
    return {
      textValue: this.modelValue,
      show: false,
      hover: false,
    };
  },

  props: {
    keyName: {
      default: "nationality",
    },
    modelValue: {
      default: "cv",
    },
    datas: {
      default: [],
    },
    title: {
      default: "",
    },
    exists:{
       default: [],
    }
  },
  watch: {
    textValue: {
      deep: true,
      handler: function (newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    modelValue: {
      deep: true,
      handler: function (newVal) {
        this.textValue = newVal;
      },
    },
  },
  computed: {
    filtredCountries: function () {
 return (this.datas || []).filter(item => {
if(this.exists.includes(item))return false;
if(this.textValue && this.textValue.trim() !=''  &&  !item.toLowerCase().includes(this.textValue.trim().toLowerCase()) )return false;
 return true;
});

     
    },
  },

  methods: {
    selectOp(text) {
      this.textValue = text;
      this.hover = this.show = false;
    },
    focusout() {
      if (this.hover) return;

      this.show = false;
    },
  },
};
</script>

<style scoped>
.form-floating {
white-space:unset;
    overflow:unset;
}
.form-floating label {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}
.ulAuto {
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 200px;
  overflow-y: auto;
}
.liAuto {
  padding: 2px 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-floating > label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
@media (max-width: 768px) {

  .liAuto {
  padding: 4px 12px;
}
}
</style>