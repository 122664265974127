import Vuex from "vuex";
import api from "@/services/api";
import CardExperience from "@/components/CardExperience";
import CardEducation from "@/components/CardEducation";
import CardCertification from "@/components/CardCertification";

import CardLanguage from "@/components/CardLanguage";
import CardDigitalSkills from "@/components/CardDigitalSkills";
import CardSoftSkills from "@/components/CardSoftSkills";
//import ModelTemplate from "@/components/ModelTemplate";
import T1 from "@/components/templates/T1/T1";
import CardOther from "@/components/CardOther";
import ImageCrop from "@/components/subComponrnts/ImageCrop";

import Phone from "@/components/input/Phone";

import AutoComplete from "@/components/input/AutoComplete";
import actions from "@/plugins/actions";
import Alert from "@/components/alert/Alert";

import { Modal } from "bootstrap";
import ViewCV from "@/components/modal/ViewCV";
import NewCurriculumVersionOption from "@/components/modal/NewCurriculumVersionOption";

import Layout from "@/components/ico/Layout";

import ModalConfigs from "@/components/config/ModalConfigs";

import CardImage from "@/components/subComponrnts/CardImage";
import HorizontalAdst from "@/components/adsterra/HorizontalAdst";

import { listLanguagesArr } from "@/plugins/helpers";

import SelectAddRemove from "@/components/input/SelectAddRemove";
import { useCookies } from "vue3-cookies";
import { applyDrag } from "@/utils/helpers";
const { cookies } = useCookies();
export default {
  name: "Curriculum",
  components: {
    SelectAddRemove,
    HorizontalAdst,
    ModalConfigs,
    CardImage,
    Layout,
    AutoComplete,
    ViewCV,
    NewCurriculumVersionOption,
    Phone,
    ImageCrop,
    CardSoftSkills,
    CardDigitalSkills,
    CardLanguage,
    CardExperience,
    CardEducation,
    CardCertification,
    Alert,
    // ModelTemplate,
    T1,
    CardOther,
  },
  data() {
    return {
      adsActive: process.env.VUE_APP_ADS,
      firstTime_openModal_create_new_version: false,
      newVersionData: null,
      userCurriculums: null,
      userDatauser: null,

      curriculumVersion: 0,
      listLanguages: listLanguagesArr(),

      isLoadingProfileImage: true,
      templateOrder: [
        16,
        9,
        18,
        15,
        12,
        11,
        10,
        8,
        1,
        14,
        17,
        24,
        2,
        13,
        19,
        6,
        20,
        23,
        7,
        4,
        5,
        21,
        22,
        3,
      ],
      templateOption: [
        {
          template: 1,
          positionStyle: 1,
          modeloStyle: 1,
          style: 4,
          navPadding: 0,
        },
        {
          template: 1,
          positionStyle: 1,
          modeloStyle: 1,
          style: 1,
          navPadding: 1,
        },
        {
          template: 1,
          positionStyle: 1,
          modeloStyle: 2,
          style: 1,
          navPadding: 0,
        },
        {
          template: 1,
          positionStyle: 1,
          modeloStyle: 2,
          style: 1,
          navPadding: 1,
        },
        {
          template: 1,
          positionStyle: 1,
          modeloStyle: 1,
          style: 4,
          navPadding: 1,
        },
        {
          template: 1,
          positionStyle: 1,
          modeloStyle: 2,
          style: 4,
          navPadding: 1,
        },
        {
          template: 1,
          positionStyle: 2,
          modeloStyle: 1,
          style: 1,
          navPadding: 0,
        },
        {
          template: 1,
          positionStyle: 2,
          modeloStyle: 1,
          style: 4,
          navPadding: 1,
        },
        {
          template: 1,
          positionStyle: 2,
          modeloStyle: 2,
          style: 1,
          navPadding: 0,
        },
        {
          template: 1,
          positionStyle: 2,
          modeloStyle: 2,
          style: 1,
          navPadding: 1,
        },
        {
          template: 1,
          positionStyle: 2,
          modeloStyle: 1,
          style: 1,
          navPadding: 1,
        },
        {
          template: 1,
          positionStyle: 2,
          modeloStyle: 2,
          style: 4,
          navPadding: 1,
        },
      ],
      layoutActive: 1,
      templatesAvailable: [1, 4],
      setLoadPreview: false /* set template and view*/,
      activeNavsTabs: [],
      activeModalPreview: false,
      occupationsjson: null,
      windowWidth: window.innerWidth,
      urlPreview: "",
      loading: false,
      full_nameValid: true,
      emailValide: true,
      imgValide: true,
      pageData: [],
      alert: {
        msg: "",
        type: "",
      },
    };
  },

  created() {
    try {
      for (let index = 0; index < 2; index++) {
        this.userCurriculums = this.user
          ? Object.assign({}, this.user.curriculums)
          : null;
        if (this.userCurriculums) break;
        index = 0;
      }
    } catch (e) {
      console.log(e);
    }
    this.userData = this.userCurriculums[this.curriculumVersion];
    this.changeLanguage(this.userData.lang);

    api({
      method: "get",
      url: "/pageData",
    })
      .then((response) => {
        if (!response.data.data.error) {
          this.pageData = response.data.data;

          // if is new user
          if (this.selectTemplate != -1) {
            let possition = this.selectTemplate;
            this.userData.config = this.pageData.config[possition]
              ? this.pageData.config[possition]
              : this.pageData.config[0];

            actions.dispatch("showLogin", -1);
          }

          //Get template by parametre
          if (this.$route.params.id) {
            let possition = this.$route.params.id;
            this.userData.config = this.pageData.config[possition]
              ? this.pageData.config[possition]
              : this.pageData.config[0];

            history.replaceState(
              null,
              null,
              window.location.origin + "/curriculum"
            );
            this.loadPreview();
          }
          //Get template by parametre personalize
          else if (this.$route.params.personalize) {
            this.userData.config =
              this.pageData.config.find(
                (element) => element.orderr == this.$route.params.personalize
              ) ?? this.pageData.config[0];

            this.loading = false;

            if (!this.user.new) {
              this.activeModalPreview = true;
              this.mdModel.show();
            }

            history.replaceState(
              null,
              null,
              window.location.origin + "/curriculum"
            );

            setTimeout(() => {
              actions.dispatch("loading", false);
            }, 1500);
          }
        } else {
          if (response.data.data.messages)
            console.log(response.data.data.messages);
          else console.log("Internal Server Error");
        }
      })
      .catch((error) => {
        console.log("Internal Server Error " + error.message);
      });
  },

  computed: {
    curriculumVersionID: function() {
      return this.userCurriculums[this.curriculumVersion].id;
    },

    occupations: function() {
      let langName = "occupations_" + this.$i18n.locale;
      return this.occupationsjson?.[langName]
        ? this.occupationsjson[langName]
        : null;
    },
    userPhotoUrl: function() {
      if (this.userCurriculums[this.curriculumVersion].photoUrl)
        return this.userCurriculums[this.curriculumVersion].photoUrl;
      return require("@/assets/img/default-user.jpg");
    },

    selectedTemplate: function() {
      return (
        "T" +
        this.userCurriculums[this.curriculumVersion].config.template +
        "" +
        this.userCurriculums[this.curriculumVersion].config.positionStyle +
        "" +
        this.userCurriculums[this.curriculumVersion].config.modeloStyle +
        "" +
        this.userCurriculums[this.curriculumVersion].config.style +
        "" +
        this.userCurriculums[this.curriculumVersion].config.navPadding
      );

      /*
      return (
        "T" +
        this.userData.config.template +
        "" +
        this.userData.config.positionStyle +
        "" +
        this.userData.config.modeloStyle +
        "" +
        this.userData.config.style +
        "" +
        this.userData.config.navPadding
      );
      */
    },
    ...Vuex.mapGetters(["user"]),
    ...Vuex.mapGetters(["selectTemplate"]),
  },

  watch: {
    curriculumVersion: {
      deep: true,
      handler: function() {
        this.setUpdateCurriculumVersion();
      },
    },
  },
  mounted() {
    this.addDefaultDatas();
    this.mdPreview = new Modal(document.getElementById("mdPreview"));

    this.mdModel = new Modal(document.getElementById("mdModel"));

    this.mdModalPicture = new Modal(document.getElementById("mdModalPicture"));
    setTimeout(() => {
      this.occupationsjson = require("@/data/occupations.json");
    }, 1000);

    this.mdNewCurriculumVersionOption = new Modal(
      document.getElementById("mdNewCurriculumVersionOption"),
      {
        backdrop: "static",
        keyboard: true,
      }
    );

    if (this.user.new) {
      this.mdNewCurriculumVersionOption = new Modal(
        document.getElementById("mdNewCurriculumVersionOption"),
        {
          backdrop: "static",
          keyboard: true,
        }
      );
      this.addNewOptionCurriculum({ type: "openModal_firstTime" });
    } else
      this.mdNewCurriculumVersionOption = new Modal(
        document.getElementById("mdNewCurriculumVersionOption")
      );
  },

  methods: {
    changeLanguage(langCode) {
      if (this.$i18n.locale !== langCode) {
        this.$i18n.locale = langCode;

        //  if (cookies.get("aceptCookies")) { }
        if (!langCode) langCode = "en";
        cookies.set("lang", langCode, "10Y");

        actions.dispatch("language", langCode);

        let baseURL = api.defaults.baseURL;
        baseURL = baseURL.slice(0, baseURL.lastIndexOf("/"));
        //remove /
        api.defaults.baseURL =
          baseURL.slice(0, baseURL.lastIndexOf("/")) + "/" + langCode + "/";
        //remove /
      }
    },
    setUpdateCurriculumVersion() {
      try {
        this.fakeLoading();
        this.userData = this.userCurriculums[this.curriculumVersion];
        this.changeLanguage(this.userData.lang);

        this.addDefaultDatas();
      } catch (e) {
        console.log(e);
      }
    },
    checkResponsePopUp(id, calback) {
      const result = this.$store.getters.popupMessage;

      if (result && result.id && result.id == id) {
        if (result.res === true) {
          actions.dispatch("popupMessage", null);
          calback();
          return;
        } else if (result.res == null)
          setTimeout(() => {
            this.checkResponsePopUp(id, calback);
          }, 400);
      }
    },
    confirmeRemoveOptionCurriculum(index) {
      let id = Math.floor(Math.random() * 1000000000).toString(16);
      actions.dispatch("popupMessage", {
        title: this.$t("labels.confirm_deletion"),
        msg: this.$t("messages.confirmeRemoveCurriculumVersion"),
        type: "confirmDelete",
        res: null,
        id: id,
      });

      this.checkResponsePopUp(id, () => {
        if (this.user.curriculums[index].id) {
          api({
            method: "post",
            url: "/me/delect/curriculum",
            data: {
              id: this.user.curriculums[index].id,
            },
          })
            .then((response) => {
              if (!response.data.error) {
                this.removeOptionCurriculum(index);
              } else throw new Error(response.data.messages);
            })
            .catch((error) => {
              actions.dispatch("tostMessage", {
                msg: error.message,
                type: "warring",
              });
            });
        } else this.removeOptionCurriculum(index);
      });
    },
    removeOptionCurriculum(index) {
      this.user.curriculums.splice(index, 1);

      if (this.curriculumVersion >= index) {
        if (index == 0 && this.curriculumVersion == 0)
          this.curriculumVersion = 0;
        else this.curriculumVersion = this.curriculumVersion - 1;
      }

      this.userCurriculums = this.user
        ? Object.assign({}, this.user.curriculums)
        : null;

      if (index == 0 && this.curriculumVersion == 0) {
        this.setUpdateCurriculumVersion();
      }
      actions.dispatch("tostMessage", {
        msg: this.$t("messages.deleted_successfully"),
        type: "success",
      });
    },

    async addNewOptionCurriculum(let_data) {
      let length = this.user.curriculums.length;

      if (this.user.email != "youremailseuemail@gmail.com" && length >= 5) {
        actions.dispatch("tostMessage", {
          msg: this.$t("messages.limit_maximum_version"),
          type: "warning",
        });

        return;
      }

      if (!this.user.curriculums[length - 1].id) {
        actions.dispatch("tostMessage", {
          msg: this.$t("messages.notPreviewedVersion"),
          type: "warning",
        });

        return;
      }
      this.firstTime_openModal_create_new_version = false;

      if (let_data.type == "openModal_firstTime") {
        this.firstTime_openModal_create_new_version = true;
        this.mdNewCurriculumVersionOption.show();
        return;
      } else if (let_data.type == "openModal") {
        this.mdNewCurriculumVersionOption.show();
        return;
      } else if (let_data.type == "duplicar") {
        let duplicado = Object.assign({}, this.userCurriculums[let_data.index]);
        duplicado.version_name = ("(new) " + duplicado.version_name).slice(
          0,
          25
        );

        duplicado.id = null;

        this.user.curriculums.push(Object.assign({}, duplicado));
      } else {
        if (let_data.first) this.newVersionData = this.user.curriculums[0];
        else {
          this.newVersionData = JSON.parse(
            JSON.stringify(await require("@/data/newVersionData.json"))
          );
        }

        this.newVersionData.lang = this.$i18n.locale;
        this.newVersionData.version_name = let_data.version_name;
        this.newVersionData.full_name = this.user.full_name;
        this.newVersionData.email = this.user.email;
        this.newVersionData.dialCode = this.user.dialCode;
        this.newVersionData.country = this.user.country;
        this.newVersionData.config = this.pageData.config[0];
        this.newVersionData.profession = let_data.profession;
        this.mdNewCurriculumVersionOption.hide();

        if (let_data.type == "AI") {
          let responseData = await this.makeApiRequest(this.newVersionData);
          if (!responseData) return;
          this.newVersionData = responseData.data;
        }

        //this.newVersionData.id = newID;
        if (let_data.first) {
          //config by url user choose

          if (this.$route.params.personalize)
            this.newVersionData.config = this.userData.config;
          //this.userData
          length = 0; //this.curriculumVersion = length;
          this.user.curriculums[0] = Object.assign({}, this.newVersionData);
        } else
          this.user.curriculums.push(Object.assign({}, this.newVersionData));
      }

      this.userCurriculums = this.user
        ? Object.assign({}, this.user.curriculums)
        : null;
      this.curriculumVersion = length;

      if (let_data.first) {
        //force update
        this.setUpdateCurriculumVersion();
        this.mdNewCurriculumVersionOption = new Modal(
          document.getElementById("mdNewCurriculumVersionOption")
        );
      }
      if (let_data.type == "AI") {
        setTimeout(() => {
          this.activeModalPreview = true;
          this.mdModel.show();
        }, 1000);
      }

      console.log(2, this.newVersionData);
    },

    async makeApiRequest(curriculumData) {
      actions.dispatch("popupMessage", {
        title: this.$t("labels.please_wait_a_few_seconds"),
        msg: this.$t("labels.the_artificial_intelligence_is_working_for_you"),
        type: "loading",
        res: null,
      });
      try {
        const response = await api({
          method: "post",
          url: "/me/curriculum/aiGenerator",
          data: curriculumData,
        });

        if (!response.data.error) {
          actions.dispatch("popupMessage", null);
          return response.data;
        } else {
          throw new Error(response.data.messages);
        }
      } catch (error) {
        // Tratar erros aqui
        actions.dispatch("tostMessage", {
          msg: error.message,
          type: "warning",
        });

        actions.dispatch("popupMessage", null);
        return false;
      }
    },

    fakeLoading() {
      actions.dispatch("loading", true);
      setTimeout(() => {
        actions.dispatch("loading", false);
      }, 1700);
    },
    addDefaultDatas() {
      if (this.userData.experiences.length < 1) this.addExperience();
      if (this.userData.educations.length < 1) this.addEducation();
      if (this.userData.certifications.length < 1) {
        this.addCertification();
        this.addCertification();
        this.addCertification();
      }

      if (this.userData.languages.length < 1) {
        this.addLanguage();
        this.addLanguage();
        this.addLanguage();
      }
      if (this.userData.digitalSkills.length < 1) {
        this.addDigitalSkill();
        this.addDigitalSkill();
        this.addDigitalSkill();
      }
      if (this.userData.softSkills.length < 1) {
        this.addSoftSkill();
        this.addSoftSkill();
        this.addSoftSkill();
      }
      if (this.userData.config.s_bg == "") {
        this.setDefaultColorTemplate();
        this.setDefaultDigitalSkill();
        this.setDefaultSoftSkill();
        this.setDefaultLanguage();
      }
    },
    loadedProfileImage() {
      this.isLoadingProfileImage = false;
    },
    personalize(id) {
      this.goToViewPersonalise(id - 1);
    },
    view(id) {
      this.goToViewPersonalise(id - 1, true);
    },

    listExperienceClick() {
      let i = this.userData.config.carExperenceEducationStyle + 1;
      if (i > 4) i = 1;

      this.userData.config.carExperenceEducationStyle = i;
    },

    openModalConfigs(type) {
      this.$refs.ModalConfigs.show(type);
    },

    setActiveLayout() {},
    goToViewPersonalise(i, view = false) {
      actions.dispatch("loading", true);
      this.userData.config = this.pageData.config[i]
        ? this.pageData.config[i]
        : this.pageData.config[0];

      if (view) this.loadPreview();
      else {
        this.$refs.navPersonalize.click();
        setTimeout(() => {
          actions.dispatch("loading", false);
        }, 1500);
      }
    },

    randomConfig() {
      actions.dispatch("loading", true);
      this.userData.config = this.pageData.config[
        Math.floor(Math.random() * this.pageData.config.length)
      ];

      setTimeout(() => {
        actions.dispatch("loading", false);
      }, 1500);
    },
    someMethod(menu) {
      if (this.activeNavsTabs.indexOf(menu) === -1) {
        this.activeNavsTabs.push(menu);
      }
      //Set Scrooll Possition
      const nav_tabContent = this.$refs.nav_tabContent.getBoundingClientRect()
        .left;
      let left =
        this.$refs[menu].getBoundingClientRect().left +
        this.$refs.navTab.scrollLeft -
        nav_tabContent;
      //button menu size
      let buttonWidth = this.$refs[menu].offsetWidth / 2;
      //window
      left = left - this.$refs.nav_tabContent.offsetWidth / 2 + buttonWidth;

      //set scroll position
      this.$refs.navTab.scrollLeft = left;

      //End Scrooll Possition
    },
    updateOtherInfo(val) {
      this.userData.drivingLicence = val.drivingLicence;
      this.userData.linkedin = val.linkedin;
      this.userData.portfolio = val.portfolio;
      this.userData.nationality = val.nationality;
      this.userData.gender = val.gender;
      this.userData.date_birth = val.date_birth;
    },
    selectItem(template, positionStyle, modeloStyle, style, navPadding) {
      this.userData.config.template = template;
      this.userData.config.positionStyle = positionStyle;
      this.userData.config.style = style;
      this.userData.config.modeloStyle = modeloStyle;
      this.userData.config.navPadding = navPadding;

      if (this.setLoadPreview) this.loadPreview();
      this.setLoadPreview = false;
      // this.selectedTemplate='T'+template+''+positionStyle+''+	style;
    },
    templateNavPaddingChane() {
      this.userData.config.navPadding =
        this.userData.config.navPadding == 1 ? 0 : 1;
    },

    saveConfig() {
      api({
        method: "post",
        url: "/me/update/config",
        data: {
          id: this.curriculumVersionID,
          config: this.userData.config,
        },
      })
        .then((response) => {
          if (response.data.error) {
            if (response.data.status == "4001") {
              actions.dispatch("expiredLogin", {
                userID: this.userData.id,
                msg: response.data.messages,
                type: "danger",
              });
            } else throw new Error(response.data.messages);
          }
        })
        .catch((error) => {
          return {
            error: true,
            msg: error.message,
          };
        });
    },

    setDefaultColorTemplate() {
      this.userData.config.p_color = "#111111";
      this.userData.config.s_color = "#5f5f5f";
      this.userData.config.o_color = "#289AA9";

      this.userData.config.bg_color = "#FFFFFF";
      this.userData.config.p_bg = "#AFDFE9";
      this.userData.config.s_bg = "#FFFFFF";
      this.saveConfig();
    },
    loadPreview() {
      actions.dispatch("loading", true);
      api({
        url: `me/viewCurriculum`,
        method: "POST",
        data: {
          config: this.userData.config,
          id: this.curriculumVersionID,
        },
        responseType: "arraybuffer",
      })
        .then((response) => {
          try {
            var decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(response.data)
            );
            let obj = JSON.parse(decodedString);
            if (obj["error"]) {
              if (obj["status"] == "4001") {
                actions.dispatch("loading", false);
                actions.dispatch("expiredLogin", {
                  userID: this.userData.id,
                  msg: obj["messages"],
                  type: "danger",
                });
                return;
              } else {
                throw new Error(obj["messages"]);
              }
            }
          } catch (e) {
            this.saveConfig();
            // this.mdModel.hide();
          }

          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          this.urlPreview = window.URL.createObjectURL(blob);
          this.mdPreview.show();
        })
        .catch((error) => {
          this.alert.msg = error.message;
          this.alert.type = "danger";
          this.loading = false;
        })
        .finally(() => {
          actions.dispatch("loading", false);
        });
    },
    saveUserImage(imgg) {
      this.userData.photoUrl = imgg;
      this.mdModalPicture.hide();
      this.alert.msg = "Image updated successfully";
      this.alert.type = "success";
    },

    save() {
      this.full_nameValid = this.$valid(this.userData.full_name);
      if (!this.full_nameValid) return;

      this.emailValide = this.$validEmail(this.userData.email);
      if (!this.emailValide) return;

      let check_experience_or_education = false;

      this.userData.experiences.forEach((element) => {
        if (element.title.trim().length > 0) {
          check_experience_or_education = true;
          return;
        }
      });
      //education
      if (check_experience_or_education == false) {
        this.userData.educations.forEach((element) => {
          if (element.title.trim().length > 0) {
            check_experience_or_education = true;
            return;
          }
        });
      }

      //certification

      if (check_experience_or_education == false) {
        this.userData.certifications.forEach((element) => {
          if (element.title.trim().length > 0) {
            check_experience_or_education = true;
            return;
          }
        });
      }

      //digitalSkill

      if (check_experience_or_education == false) {
        this.userData.digitalSkills.forEach((element) => {
          if (element.skill.trim().length > 0) {
            check_experience_or_education = true;
            return;
          }
        });
      }
      //softSkills
      if (check_experience_or_education == false) {
        this.userData.softSkills.forEach((element) => {
          if (element.skill.trim().length > 0) {
            check_experience_or_education = true;
            return;
          }
        });
      }

      if (check_experience_or_education == false) {
        actions.dispatch("tostMessage", {
          msg: this.$t("messages.to_proceed_add_education_or_experience"),
          type: "warning",
        });
        return;
      }
      this.loading = true;
      api({
        method: "post",
        url: "/me/update/curriculum",
        data: this.userData,
      })
        .then((response) => {
          if (!response.data.error) {
            //Set USer
            //let zzz = this.user;
            // zzz.curriculums[this.curriculumVersion] = this.userData;
            //this.$store.dispatch("user", zzz);

            //this.alert.msg = response.data.messages;
            //this.alert.type = "success";
            this.userData.id = response.data.curriculum_id;

            this.activeModalPreview = true;
            this.loading = false;
            this.mdModel.show();
          } else {
            if (response.data.status == "4001") {
              this.loading = false;
              actions.dispatch("expiredLogin", {
                userID: this.userData.id,
                msg: response.data.messages,
                type: "danger",
              });
            } else throw new Error(response.data.messages);
          }
        })
        .catch((error) => {
          actions.dispatch("tostMessage", {
            msg: error.message,
            type: "warning",
          });

          this.loading = false;
        });
    },
    resetImageUploader() {
      this.$refs.fileInput.value = "";
    },
    loadModalCrop(e) {
      let file = this.$refs.fileInput.files;
      if (file && file[0]) {
        actions.dispatch("loading", true);
        this.mdModalPicture.show();
        setTimeout(() => {
          this.$refs.childComponent.setImage(e);
          actions.dispatch("loading", false);
        }, 1000);
      }
    },

    openinputFile() {
      this.$refs.fileInput.click();
    },

    //SoftSkill
    onDropSoftSkill(dropResult) {
      this.userData.softSkills = applyDrag(
        this.userData.softSkills,
        dropResult
      );
    },
    addSoftSkill() {
      this.userData.softSkills.push({
        user_id: "",
        skill: "",
        level: 3,
      });
    },
    removeSoftSkill(index) {
      if (this.userData.softSkills.length > 1)
        this.userData.softSkills.splice(index, 1);
    },

    //digitalSkills
    onDropDigitalSkill(dropResult) {
      this.userData.digitalSkills = applyDrag(
        this.userData.digitalSkills,
        dropResult
      );
    },

    addDigitalSkill() {
      this.userData.digitalSkills.push({
        user_id: "",
        skill: "",
        level: 3,
      });
    },
    removeDigitalSkill(index) {
      if (this.userData.digitalSkills.length > 1)
        this.userData.digitalSkills.splice(index, 1);
    },

    //languages
    onDropLanguage(dropResult) {
      this.userData.languages = applyDrag(this.userData.languages, dropResult);
    },
    addLanguage() {
      this.userData.languages.push({
        user_id: "",
        language: "",
        level: 3,
      });
    },
    removeLanguage(index) {
      if (this.userData.languages.length > 1)
        this.userData.languages.splice(index, 1);
    },

    //Experience

    onDropExperience(dropResult) {
      this.userData.experiences = applyDrag(
        this.userData.experiences,
        dropResult
      );
    },
    addExperience() {
      /*  let country = this.userData.country;
      if (country == "") country = "cv";
      */
      this.userData.experiences.push({
        user_id: "",
        title: "",
        employer: "",
        /* country: country,*/
        city: "",
        fromDt: "",
        toDt: "",
        ongoing: false,
        description: "",
      });
    },
    removeExperience(index) {
      if (this.userData.experiences.length > 1)
        this.userData.experiences.splice(index, 1);
    },

    //Education

    onDropEducation(dropResult) {
      this.userData.educations = applyDrag(
        this.userData.educations,
        dropResult
      );
    },
    addEducation() {
      /* let country = this.userData.country;
      if (country == "") country = "cv";*/
      this.userData.educations.push({
        user_id: "",
        title: "",
        organization: "",
        /* country: country,*/
        city: "",
        fromDt: "",
        toDt: "",
        ongoing: false,
        description: "",
      });
    },

    //Certification

    onDropCertification(dropResult) {
      this.userData.certifications = applyDrag(
        this.userData.certifications,
        dropResult
      );
    },

    addCertification() {
      this.userData.certifications.push({
        user_id: "",
        title: "",
        organization: "",
        city: "",
        fromDt: "",
        toDt: "",
        ongoing: false,
        description: "",
      });
    },
    removeCertification(index) {
      if (this.userData.certifications.length > 1)
        this.userData.certifications.splice(index, 1);
    },
    removeEducation(index) {
      if (this.userData.educations.length > 1)
        this.userData.educations.splice(index, 1);
    },
  },
};
