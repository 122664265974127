<template>
  <div>
    <div class="bootstrap-tagsinput" ref="infoBox" @click="areaClicked">
      <label
        for="floatingInput"
        style="
          opacity: 0.65;
          white-space: nowrap;
          overflow: hidden;
          width: 100%;
          font-size: 0.85rem;
          margin-bottom: -4px;
        "
        >  {{ $te('labels.'+title) ? this.$t('labels.'+title) : title }}</label
      >
      <span
        v-for="(hobbie, index) in arrValues"
        :key="hobbie"
        class="badge text-bg-primary"
      >
        {{ hobbie.skill }}
        <span data-role="remove">
          <button
            @click="remove(index)"
            type="button"
            class="btn-close"
            aria-label="Close"
          ></button> </span
      ></span>

      <input
        v-on:keyup.enter="addValue"
        :value="textValue"
        @input="(evt) => (textValue = evt.target.value)"
        ref="searchTextInput"
      
        @focus="show = true"
        type="text"
        placeholder=""
        :size="textValue.length"
      />
    </div>
    <transition name="fade">
      <ul
        :style="{ width: width }"
        ref="infoBox2"
        v-if="show"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        class="list-unstyled ulAuto"
      >
        <li
          v-for="countrie in filtredCountries"
          :key="countrie"
          @click="selectOp(countrie)"
          class="list-unstyled sgID liAuto"
        >
          {{ countrie }}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: "inputTags",
  data() {
    return {
      show: false,
      hover: false,
      textValue: "",
      arrValues: this.modelValue,
      width: "100%",
    };
  },

  props: {
    title: {
      default: "",
    },
    modelValue: {
      default: [],
    },
    keyName: {
      default: "nationality",
    },

    datas: {
      default: [],
    },
  },

  methods: {
    remove(index) {
      this.arrValues.splice(index, 1);
    },
    reziseBox() {
      this.width = this.$refs.infoBox.clientWidth + "px";
    },
    selectOp(text) {
      this.textValue = text;
      this.addValue();
      this.hover = this.show = false;
    },
    focusout() {
      if (this.hover) return;

      this.show = false;
    },

    areaClicked() {
      this.$refs.searchTextInput.focus();
    },
    addValue() {
      let val = this.textValue.trim();
      if (val != "") {
        this.arrValues.push({
          user_id: "",
          skill: val,
        });
        this.textValue = "";
      }
    },
  },
  watch: {
    arrValues: {
      deep: true,
      handler: function (newVal) {
        console.log(newVal);
        this.$emit("update:modelValue", newVal);
      },
    },

    show: {
      deep: true,
      handler: function () {
        this.reziseBox();
      },
    },
    modelValue: {
      deep: true,
      handler: function (newVal) {
        this.arrValues = newVal;
      },
    },
  },
  computed: {
    filtredCountries: function () {
      return (this.datas || []).filter((item) => {
        if (this.arrValues.some((val) => val.skill === item)) return false;
        //if (this.arrValues.includes(item)) return false;
        if (
          this.textValue &&
          this.textValue.trim() != "" &&
          !item.toLowerCase().includes(this.textValue.trim().toLowerCase())
        )
          return false;
        return true;
      });
    },
  },

  mounted() {
    window.onresize = () => {
      this.reziseBox();
    };
  },
};
</script>

<style scoped>
.badge {
  cursor: context-menu;
}
.btn-close {
  width: 0.5em;
  height: 0.5em;
  opacity: 0.7;
  margin-left: 5px;
}
.bootstrap-tagsinput .badge {
  margin: 2px;
  padding: 5px 8px;
  border-radius: 0.25rem;
  border: 1px solid;
}
.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  padding: 0 6px;
  margin: 0;
  width: auto;
  max-width: inherit;
}
.bootstrap-tagsinput {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  display: inline-block;
  padding: 0.375rem 0.75rem;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  width: 100%;
  line-height: 22px;
  cursor: text;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important;
}
.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important;
}
</style>

<style scoped>
.bootstrap-tagsinput {
  white-space: unset;
  overflow: unset;
}

.ulAuto {
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 200px;
  overflow-y: auto;
}
.liAuto {
  padding: 2px 12px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media (max-width: 768px) {
  .liAuto {
    padding: 4px 12px;
  }
}
</style>