<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="starSize" viewBox="0 0 24 24">
    <path v-if="tp==1"
      :class="svg_status"
      
      d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z"
    />
     <circle
     v-else
      cx="14"
      cy="14"
      r="10"
         :class="svg_status"
     
    />
  </svg>
</template>

<script>
export default {
  name: "star",

  props: {
     tp:{
      default: "1",
    },
    svg_status:{
      default: "svg_active",
    },
    color: {
      default: "#27BC57",
    },
    starSize: {
      default:5,
    },
  },
};
</script>

<style scoped>
.my-svg-component {
  /* Scoped CSS here */
}
</style>