<template>

  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="100px" height="100px" viewBox="0 0 1320 1320" enable-background="new 0 0 1320 1320" xml:space="preserve">
<rect x="0" y="0" width="1320" height="1320" :fill="`var(${bgColor})`" /><g transform="translate(80,80)"><g transform="translate(320,0) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,0) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,0) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,0) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,0) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,0) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,0) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,40) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,80) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,80) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,80) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,80) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,80) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,80) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,80) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,160) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,200) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,200) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,200) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,200) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,200) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,240) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,240) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,240) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,240) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,240) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,240) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,240) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,280) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,280) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,280) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(120,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(160,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(200,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(240,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(280,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,320) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(80,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,360) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(40,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(80,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(160,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(200,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(240,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,400) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(120,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(160,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,440) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(40,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(80,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(120,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(240,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,480) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(80,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,520) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(40,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(200,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(240,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(280,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,560) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(40,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(160,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(280,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,600) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(160,640) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(240,640) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,640) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,640) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,640) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,640) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,640) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(120,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,680) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(40,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(80,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(240,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(280,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,720) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(40,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(160,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(200,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(280,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,760) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(40,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(80,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(200,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(240,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,800) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,840) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,880) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(360,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,920) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,960) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(560,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,1000) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(400,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(640,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1080,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,1040) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(520,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(600,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(840,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(920,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1040,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1120,1080) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(320,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(440,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(480,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(680,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(720,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(760,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(800,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(880,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(1000,1120) scale(0.4,0.4)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(0,0) scale(2.8, 2.8)"><g transform=""  :style="`fill: var(${textColor})`">
<g>
	<rect x="15" y="15" style="fill:none;" width="70" height="70"/>
	<path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z"/>
</g>
</g></g><g transform="translate(880,0) scale(2.8, 2.8)"><g transform=""  :style="`fill: var(${textColor})`">
<g>
	<rect x="15" y="15" style="fill:none;" width="70" height="70"/>
	<path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z"/>
</g>
</g></g><g transform="translate(0,880) scale(2.8, 2.8)"><g transform=""  :style="`fill: var(${textColor})`">
<g>
	<rect x="15" y="15" style="fill:none;" width="70" height="70"/>
	<path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z"/>
</g>
</g></g><g transform="translate(80,80) scale(1.2, 1.2)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(960,80) scale(1.2, 1.2)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g><g transform="translate(80,960) scale(1.2, 1.2)"><g transform=""  :style="`fill: var(${textColor})`">
<rect width="100" height="100"/>
</g></g></g></svg>
</template>

<script>
export default {
  name: "qrcode",
    props: ["textColor", "bgColor"],
  data() {
    return {
     
}
}
};
</script>

