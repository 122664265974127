<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="mdNewCurriculumVersionOption"
    tabindex="-1"
    aria-labelledby="mdNewCurriculumVersionOptionLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog ">
      <div
        class="modal-content p-3 bg-s"
        style="max-height: 100%; overflow: hidden"
      >
        <div class="row">
          <!-- 
           <h3 class="text-center cp mb-3 mt-2">Bem Vindo ao ThemyCV</h3>

          <h6 class="text-center  mb-3 mx-auto cp1" style="max-width: 92%;">
            Para criar o seu primeiro currículo, precisamos de algumas
            informações adicionais. Por favor, forneça-nos os seguintes
            detalhes.
          </h6>
       -->

          <h3
            v-if="firstTime_openModal_create_new_version"
            class="text-center cp mb-2 mt-2"
          >
            {{ $t("labels.welcome_to_themycv") }}
          </h3>
          <h5
            v-if="firstTime_openModal_create_new_version"
            class="text-center px-4 mb-4 mt-2"
          >
            {{
              $t("labels.we_are_almost_there_your_resume_will_be_a_success1")
            }}
            <br />
            {{
              $t("labels.we_are_almost_there_your_resume_will_be_a_success2")
            }}
          </h5>

          <h5
            v-if="!firstTime_openModal_create_new_version"
            class="text-center cp mb-4 mt-2"
          >
            {{ $t("labels.create_new_template") }}
          </h5>
          <div class="d-flex justify-content-end d-md-none d-block">
            <button
              type="button"
              class="btn-close btn-closeModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div class="row  mb-3">
            <div class="col">
              <div class="form-floating">
                <input
                  :class="{ 'is-invalid': !version_nameValide }"
                  v-model="version_name"
                  type="text"
                  class="form-control"
                  placeholder=" "
                />
                <label for="floatingInput">
                  {{ $t("labels.version_name") }}
                </label>
              </div>
            </div>
            <div
              v-if="!firstTime_openModal_create_new_version"
              class="col-auto d-flex align-items-center p-0"
            >
              <div class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle p-1"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    :src="
                      require('@/assets/img/flag_' + this.$i18n.locale + '.svg')
                    "
                    class="flag-ico"
                    :alt="'flag ' + this.$i18n.locale"
                  />
                </a>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li v-for="language in listLanguages" :key="language.key">
                    <a
                      class="dropdown-item"
                      href="#"
                      @click.prevent=""
                      @click="changeLanguage(language.key)"
                      :class="{ active: this.$i18n.locale === language.key }"
                    >
                      <img
                        :src="
                          require('@/assets/img/flag_' + language.key + '.svg')
                        "
                        class="flag-ico"
                        :alt="'flag ' + language.key"
                      />
                      {{ $t(language.label) }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col-12 ">
            <div class="row">
              <div class="col-12 mb-3">
                <AutoComplete
                  :class="{ 'is-invalid': !professionValide }"
                  v-model="profession"
                  title="profession"
                  :datas="occupations"
                />
              </div>
              <!-- -->
            </div>
            <!-- 
            <div
              class="row  mb-3"
              v-if="firstTime_openModal_create_new_version"
            >
              <div class="col-6">
                <div class="form-floating">
                  <input type="text" class="form-control" placeholder=" " />
                  <label for="floatingInput">Endereço</label>
                </div>
              </div>
              <div class="col-6">
                <div class="form-floating">
                  <input type="text" class="form-control" placeholder=" " />
                  <label for="floatingInput">Cidade</label>
                </div>
              </div>
            </div>
             -->
            <div class="row">
              <!-- 
              <div
                class="col-lg-12 mb-3"
                v-if="firstTime_openModal_create_new_version"
              >
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" placeholder=" " />
                  <label for="floatingInput">Contacto</label>
                </div>
              </div>
            -->
              <div class="col-12 mb-3">
                <div class="form-check form-switch">
                  <input 
                  disabled
                 
                    class="form-check-input"
                    type="checkbox"
                    role="switch"
                    v-model="isAIChecked"
                    id="isAIChecked"
                    checked
                  />
                  <label class="form-check-label" for="isAIChecked">
                    {{ $t("labels.create_pre-filled_resume") }}
                  </label>
                </div>
              </div>

              <div class="col-12 mt-5">
                <div class="d-flex justify-content-end mb-0 mt-0">
                  <button
                    type="button"
                    @click="criarVersion()"
                    class="btn  bg-p"
                  >
                    {{ $t("buttons.create") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutoComplete from "@/components/input/AutoComplete";
import { listLanguagesArr } from "@/plugins/helpers";
export default {
  name: "NewCurriculumVersionOption",
  data() {
    return {
      isAIChecked: false,
      listLanguages: listLanguagesArr(),
      version_nameValide: true,
      professionValide: true,
      profession: "",
      version_name: this.firstTime_openModal_create_new_version
        ? this.$t("labels.my_first_resume")
        : this.$t("labels.new_resume")
    };
  },
  props: {
    firstTime_openModal_create_new_version: {
      default: false,
    },
    occupations: {
      default: [],
    },
  },
  components: {
    AutoComplete,
  },
  computed: {
    
  },
  methods: {
    changeLanguage(data) {
      this.$emit("changeLanguage", data);
    },
    criarVersion() {
      this.version_nameValide = this.$valid(this.version_name, 0, 25);
      if (!this.version_nameValide) return;

      this.professionValide = this.$valid(this.profession, 0, 50);
      if (!this.professionValide) return;

      let datas = {
        type: this.isAIChecked ? "AI" : "",
        version_name: this.version_name,
        profession: this.profession,
        first: this.firstTime_openModal_create_new_version,
      };
      this.$emit("addNewOption", datas);
    },
  },

  mounted() {},
};
</script>

<style scoped>
.flag-ico {
  height: 20px;
  cursor: pointer;
}
</style>
