<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row g-3">
        <div class="col-md-6 col-lg-3 col-xl-4">
          <div class="form-floating">
            <input
              v-model="education.title"
              type="text"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInput" >{{ $t("labels.title_of_qualification") }} <span>*</span></label
            >
          </div>
        </div>
        <div class="col-md-6 col-lg-3 col-xl-4">
          <div class="form-floating">
            <input
              v-model="education.organization"
              type="text"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInput">{{ $t("labels.organization") }}</label>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3 col-xl-2">
          <div class="form-floating">
            <input
              v-model="education.fromDt"
              type="month"
              class="form-control"
              placeholder=" "
              min="1922-01"
              :max="maxData"
            />
            <label for="floatingInput">{{ $t("labels.from_date") }}</label>
          </div>
        </div>

        <div class="col-sm-6 col-lg-3 col-xl-2">
          <div class="form-floating">
            <input
              min="1922-01"
              :max="maxData"
              v-model="toDt"
              :disabled="education.ongoing"
              type="month"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInput">{{ $t("labels.to_date") }}</label>
            <div class="form-check">
              <input
                v-model="education.ongoing"
                class="form-check-input"
                type="checkbox"
              />
              <label class="form-check-label"
                >{{ $t("labels.ongoing") }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating form-textarea">
            <textarea
              style="height: 100px"
              v-model="education.description"
              class="form-control"
              placeholder=" "
            ></textarea>
            <label for="floatingTextarea">{{ $t("labels.description") }}</label>
          </div>
          <label class="d-flex justify-content-end">
            <img
              v-if="length > 1"
              @click="remove"
              class="btnIconRemove mt-2"
              src="@/assets/img/delete.svg"
              alt=""
          /></label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const today = new Date(+new Date() + 2678400000 * 2);

export default {
  name: "Education",
  props: ["userEducation", "index", "length"],
  data: function () {
    return {
      education: this.userEducation,
      maxData:
        today.getFullYear().toString() +
        "-" +
        ("0" + (today.getMonth() + 1).toString()).slice(-2),
    };
  },
  computed: {
    toDt: {
      get() {
        return this.education.ongoing ? "" : this.education.toDt;
      },

      set(newValue) {
        this.education.toDt = newValue;
      },
    },
  },
  methods: {
    remove() {
      this.$emit("remove", this.index);
    },
  },
};
</script>

<style scoped>
.form-textarea {
  margin-top: -8px;
}
textarea {
  height:100px;
}
@media (max-width: 600px) {
  textarea {
    height: 92px !important;
  }
}
</style>