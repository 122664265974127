
<template>
  <div class="t1-p2">
    <div class="">
      <div v-if="configColor.modeloStyle == 1" style="position: relative">
        <div
          class="qrcod col-auto"
          style="position: absolute; top: -8px; left: 34px"
        >
          <qrcode
            textColor="--nav2TextColor2"
            bgColor="--nav1BgColor2"
            style="height: 19px"
          />
        </div>
      </div>
      <div v-if="configColor.modeloStyle == 1">
        <h1  @click="$emit('openModalConfigs','Other')"  class=" cl"> {{ $te("labels." + configColor.about_me_title)  ? this.$t("labels." + configColor.about_me_title)  : configColor.about_me_title}}</h1>
        <hr />
        <p class="mt-1 tj cl"  @click="$emit('openModalConfigs','Other')" >
          is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry s standard dummy text ever since the
          1500s, when an unknown printer took a galley .
        </p>
        <h1 class="cl mt-5"  @click="$emit('openModalConfigs','Other')" >
          {{ $te("labels." + configColor.experience_title)  ? this.$t("labels." + configColor.experience_title)  : configColor.experience_title}}
        </h1>
      </div>

      <h1 v-else class="cl"  @click="$emit('openModalConfigs','Other')" >
        {{ $te("labels." + configColor.experience_title)  ? this.$t("labels." + configColor.experience_title)  : configColor.experience_title}}
      </h1>

      <hr />

      <div  @click="$emit('listExperienceClick')"  class=" cl">
        <div v-if="configColor.carExperenceEducationStyle == 1">
          <div class="mt-2" v-for="index in 1" :key="index">
            <div class="d-flex justify-content-between">
              <p class="mt-1 strong title">
                {{ $t("templeteText.administrative") }}
              </p>
              <p class="mt-1">2020-08 - {{ $t("labels.ongoing") }}</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="mt-1">{{ $t("templeteText.company_name") }}</p>
            </div>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply dummy text of the printing and Lorem Ipsum.
            </p>
          </div>
        </div>

        <div v-else-if="configColor.carExperenceEducationStyle == 2">
          <div class="mt-2">
            <p class="mt-1">
              2020-08 - {{ $t("labels.ongoing") }} |
              <span class="strong title"
                >{{ $t("templeteText.administrative") }}
              </span>
            </p>
            <p class="mt-1">{{ $t("templeteText.company_name") }}</p>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply dummy text of the printing and Lorem Ipsum.
            </p>
          </div>
        </div>
        <div v-else-if="configColor.carExperenceEducationStyle == 3">
          <div class="mt-2">
            <p class="mt-1">2020-08 - {{ $t("labels.ongoing") }}</p>
            <p class="mt-1 strong title">
              {{ $t("templeteText.administrative") }}
            </p>
            <div class="d-flex justify-content-between">
              <p class="mt-1">{{ $t("templeteText.company_name") }}</p>
            </div>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply dummy text of the printing and Lorem Ipsum.
            </p>
          </div>
        </div>

        <div v-else-if="configColor.carExperenceEducationStyle == 4">
          <div class="mt-2">
            <p class="mt-1 strong title">
              {{ $t("templeteText.administrative") }}
            </p>
            <p class="mt-1">
              2020-08 - {{ $t("labels.ongoing") }} |
              {{ $t("templeteText.company_name") }}
            </p>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply dummy text of the printing and Lorem Ipsum.
            </p>
          </div>
        </div>
      </div>

      <h1 class="cl mt-5" @click="$emit('openModalConfigs','Other')" >
         {{ $te("labels." + configColor.education_title)  ? this.$t("labels." + configColor.education_title)  : configColor.education_title}}
      </h1>
      <hr />
      <div  @click="$emit('listExperienceClick')" class=" cl">
        <div v-if="configColor.carExperenceEducationStyle == 1">
          <div class="mt-2">
            <div class="d-flex justify-content-between">
              <p class="mt-1 strong title">
                {{ $t("templeteText.administrative") }}
              </p>
              <p class="mt-1">2015-10 - 2019-06</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="mt-1">{{ $t("templeteText.university_school") }}</p>
            </div>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply.
            </p>
          </div>
        </div>

        <div v-else-if="configColor.carExperenceEducationStyle == 2">
          <div class="mt-2">
            <p class="mt-1">
              <span class="strong title">{{
                $t("templeteText.administrative")
              }}</span>
              | 2015-10 - 2019-06
            </p>
            <p class="mt-1">{{ $t("templeteText.university_school") }}</p>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply dummy text of the printing and Lorem Ipsum is simply
              dummy text of the printing and Lorem Ipsum.
            </p>
          </div>
        </div>
        <div v-else-if="configColor.carExperenceEducationStyle == 3">
          <div class="mt-2" v-for="index in 1" :key="index">
            <p class="mt-1">2020-08 - {{ $t("labels.ongoing") }}</p>
            <p class="mt-1 strong title">
              {{ $t("templeteText.administrative") }}
            </p>

            <div class="d-flex justify-content-between">
              <p class="mt-1">{{ $t("templeteText.university_school") }}</p>
            </div>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply.
            </p>
          </div>
        </div>
        <div v-else-if="configColor.carExperenceEducationStyle == 4">
          <div class="mt-2" v-for="index in 1" :key="index">
            <p class="mt-1 strong title">
              {{ $t("templeteText.administrative") }}
            </p>
            <p class="mt-1">
              {{ $t("templeteText.university_school") }} | 2020-08 -
              {{ $t("labels.ongoing") }}
            </p>
            <p class="mt-1 tj">
              Lorem Ipsum is simply dummy text of the printing and Lorem Ipsum
              is simply dummy text of the printing and Lorem Ipsum is simply
              dummy text of the printing and Lorem Ipsum.
            </p>
          </div>
        </div>
      </div>

      <h1  @click="$emit('openModalConfigs','Other')"  class="mt-5 cl">
 {{ $te("labels." + configColor.certification_title)  ? this.$t("labels." + configColor.certification_title)  : configColor.certification_title}}
      </h1>
      <hr />
      <div class="cl" @click="$emit('listExperienceClick')">
        <div v-if="configColor.carExperenceEducationStyle == 1">
          <div class="mt-2" v-for="index in 2" :key="index">
            <div class="d-flex justify-content-between">
              <p class="mt-1 strong title">
                {{ $t("templeteText.administrative") }}
              </p>
              <p class="mt-1">2015-10</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="mt-1">{{ $t("templeteText.university_school") }}</p>
            </div>
          </div>
        </div>

        <div v-else-if="configColor.carExperenceEducationStyle == 2">
          <div class="mt-2" v-for="index in 1" :key="index">
            <p class="mt-1">
              <span class="strong title">{{
                $t("templeteText.administrative")
              }}</span>
              | 2015-10
            </p>
            <p class="mt-1">{{ $t("templeteText.university_school") }}</p>
          </div>
        </div>
        <div v-else-if="configColor.carExperenceEducationStyle == 3">
          <div class="mt-2" v-for="index in 1" :key="index">
            <p class="mt-1">2020-08 - {{ $t("labels.ongoing") }}</p>
            <p class="mt-1 strong title">
              {{ $t("templeteText.administrative") }}
            </p>

            <div class="d-flex justify-content-between">
              <p class="mt-1">{{ $t("templeteText.university_school") }}</p>
            </div>
          </div>
        </div>
        <div v-else-if="configColor.carExperenceEducationStyle == 4">
          <div class="mt-2" v-for="index in 1" :key="index">
            <p class="mt-1 strong title">
              {{ $t("templeteText.administrative") }}
            </p>
            <p class="mt-1">
              {{ $t("templeteText.university_school") }} |
              {{ $t("labels.ongoing") }}
            </p>
          </div>
        </div>
      </div>

      <h1 class="mt-5 cl" @click="$emit('openModalConfigs', 'soft')">
        {{
          $te("labels." + configColor.soft_skills_title)
            ? this.$t("labels." + configColor.soft_skills_title)
            : configColor.soft_skills_title
        }}
      </h1>
      <hr />
      <Evaluation
        class="cl"
        @click="$emit('openModalConfigs', 'soft')"
        :activeColor="configColor.soft_skills_active_color"
        :inactiveColor="configColor.soft_skills_inactive_color"
        :tipo_display="configColor.soft_skills_tipo_display"
        :tipo="configColor.soft_skills_tipo"
        :datas="softSkills.slice(0, limitDigital)"
      >
      </Evaluation>
    </div>

    <div
      class="t1-p2-nav"
      :class="{
        rightBorderRadius: this.configColor.modeloStyle == 1 ? false : true,
      }"
    ></div>
  </div>
</template>
<script>
import qrcode from "@/components/ico/qrcode";
import Evaluation from "@/components/templates/T1/parts/Evaluation";
export default {
  name: "Nav1",
  components: {
    Evaluation,
    qrcode,
  },
  props: ["configColor"],
  computed: {
    limitDigital: function () {
      if (this.configColor.soft_skills_tipo_display != 1) return 2;
      return 5;
    },
  },
  data() {
    return {
      softSkills: [
        { name: "teamwork" },
        { name: "communication" },
        { name: "critical_thinking" },
        { name: "thinking_outside_the_box" },
        { name: "innovation" },
      ],
    };
  },

  methods: {},
};
</script>


