<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row g-2">
        <div class="col-12">
          <OtherOpConfigTitle
            :datas="dataConfig.datas"
            v-model="dataConfig.skills_title"
            title="title"
          />
        </div>

        <div v-if="visible">
          <div class="col-12">
            <h6 class="fw-bold">Color:</h6>
          </div>
          <div class="col-auto">
            <label for="ActiveColor" class="form-label"
              ><Color
                v-model="dataConfig.skills_active_color"
                show-alpha
              />Active</label
            >
          </div>

          <div class="col-auto">
            <label for="InactiveColor" class="form-label"
              ><Color
                v-model="dataConfig.skills_inactive_color"
                show-alpha
              />Inactive</label
            >
          </div>
        </div>

        <div class="col-12 mt-3">
          <h6 class="fw-bold">{{ $t("labels.model_evaluation") }}:</h6>
        </div>

        <div class="col-12">
          <div class="row g-4">
            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="1"
                tipo="1"
                :datas="skills_to_show"
                @click="setOptionActive(1, 1)"
                :class="{ active: (dataConfig.skills_tipo ==1 &&  dataConfig.skills_type_display==1) }"
              >
              </Evaluation>
            </div>

            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="1"
                tipo="2"
                :datas="skills_to_show"
                @click="setOptionActive(2, 1)"
                :class="{ active: (dataConfig.skills_tipo ==2 &&  dataConfig.skills_type_display==1) }"
              >
              </Evaluation>
            </div>

            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="1"
                tipo="3"
                :datas="skills_to_show"
                @click="setOptionActive(3, 1)"
                :class="{ active: (dataConfig.skills_tipo ==3 &&  dataConfig.skills_type_display==1) }"
              >
              </Evaluation>
            </div>

            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="1"
                tipo="4"
                :datas="skills_to_show"
                @click="setOptionActive(4, 1)"
                :class="{ active: (dataConfig.skills_tipo == 4 &&  dataConfig.skills_type_display==1) }"
              >
              </Evaluation>
            </div>
            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="0"
                tipo="1"
                :datas="skills_to_show"
                @click="setOptionActive(1, 0)"
                :class="{ active: (dataConfig.skills_tipo == 1 &&  dataConfig.skills_type_display==0) }"
              >
              </Evaluation>
            </div>

            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="0"
                tipo="2"
                :datas="skills_to_show"
                @click="setOptionActive(2, 0)"
                :class="{ active: (dataConfig.skills_tipo == 2 &&  dataConfig.skills_type_display==0) }"
              >
              </Evaluation>
            </div>

            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="0"
                tipo="3"
                :datas="skills_to_show"
                @click="setOptionActive(3, 0)"
                :class="{ active: (dataConfig.skills_tipo == 3 &&  dataConfig.skills_type_display==0) }"
              >
              </Evaluation>
            </div>

            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="0"
                tipo="4"
                :datas="skills_to_show"
                @click="setOptionActive(4, 0)"
                :class="{ active: (dataConfig.skills_tipo == 4 &&  dataConfig.skills_type_display==0) }"
              >
              </Evaluation>
            </div>
            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="0"
                tipo="0"
                :datas="skills_to_show1"
                @click="setOptionActive(0, 0)"
                :class="{ active: (dataConfig.skills_tipo == 0 &&  dataConfig.skills_type_display==0) }"
              >
              </Evaluation>
            </div>
            <div class="col-12 col-md-6">
              <Evaluation
                class="cl card_evolution"
                :activeColor="config.language_active_color"
                :inactiveColor="config.language_inactive_color"
                tipo_display="1"
                tipo="0"
                :datas="skills_to_show1"
                :class="{ active: (dataConfig.skills_tipo == 0 &&  dataConfig.skills_type_display==1) }"
                @click="setOptionActive(0, 1)"
              >
              </Evaluation>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import OtherOpConfigTitle from "@/components/input/OtherOpConfigTitle";
import Evaluation from "@/components/templates/T1/parts/Evaluation";


export default {
  name: "ConfigEvaluation",
  data() {
    return {
      skills_to_show: [{ name: "skill_name" }],
      skills_to_show1: [{ name: "skill_name" }, { name: "skill_name" }],

      dataConfig: {
        skills_type_display: 0,
        skills_inactive_color: null,
        skills_active_color: null,
        skills_tipo: 2,
        skills_title: null,
        datas: [],
      },
      ratingVAl: 3,

      visible: false,
    };
  },

  watch: {
    dataConfig: {
      deep: true,
      handler: function() {
        this.saveSkillConfig();
      },
    },

    configType: {
      deep: true,
      handler: function() {
        this.show();
      },
    },
    config: {
      deep: true,
      handler: function() {
        this.show();
      },
    },
  },

  props: ["config", "configType"],

  components: {
   
  
    OtherOpConfigTitle,
    Evaluation,
  },
  mounted() {
    this.show();
  },
  methods: {
    setOptionActive(type, type_display) {
      this.dataConfig.skills_type_display = type_display;
      this.dataConfig.skills_tipo = type;
    },
    show() {
      switch (this.configType) {
        case "digital":
          this.dataConfig.skills_inactive_color = this.config.digital_skills_inactive_color;
          this.dataConfig.skills_active_color = this.config.digital_skills_active_color;
          this.dataConfig.skills_tipo = this.config.digital_skills_tipo;
          this.dataConfig.skills_type_display = this.config.digital_skills_tipo_display;
          this.title = "digital_skills";

          this.dataConfig.skills_title = this.config.digital_skills_title;
          this.dataConfig.datas = [
            "hard_skills",
            "profissional_skills",
            "technical_skills",
            "digital_skills",
            "softwares",

            "qualifications",
            "expertise",
            "skills",
          ];
          break;
        case "soft":
          this.dataConfig.skills_inactive_color = this.config.soft_skills_inactive_color;
          this.dataConfig.skills_active_color = this.config.soft_skills_active_color;
          this.dataConfig.skills_tipo = this.config.soft_skills_tipo;
          this.dataConfig.skills_type_display = this.config.soft_skills_tipo_display;
          this.title = "soft_skills";
          this.dataConfig.skills_title = this.config.soft_skills_title;
          this.dataConfig.datas = [
            "soft_skills",
            "personal_skills",

            "qualifications",
            "expertise",
            "skills",
          ];
          break;

        case "language":
          this.dataConfig.skills_inactive_color = this.config.language_inactive_color;
          this.dataConfig.skills_active_color = this.config.language_active_color;
          this.dataConfig.skills_tipo = this.config.language_tipo;
          this.dataConfig.skills_type_display = this.config.language_tipo_display;
          this.title = "language_skills";
          this.dataConfig.skills_title = this.config.language_title;

          this.dataConfig.datas = ["language_skills", "languages"];

          break;

        default:
          return;
      }
    },

    saveSkillConfig() {
      this.$emit(
        "saveSkillConfig",
        {
          inactive_color: this.dataConfig.skills_inactive_color,
          active_color: this.dataConfig.skills_active_color,
          skills_tipo: this.dataConfig.skills_tipo,
          type_display: this.dataConfig.skills_type_display,
          skills_title: this.dataConfig.skills_title,
        },
        this.configType
      );
    },
  },
};
</script>
<style>
.card_evolution .svg_rect_rx,
.card_evolution .svg_rect_rx {
  rx: 10px;
}
.card_evolution {
  --nav1TextColor2: #289aa9;
}
.card_evolution .badge {
  color: white;
  background-color: var(--nav1TextColor2);
  margin-right: 6px;
  margin-top: 0px;
  border-radius: 1rem;
  padding: 9px 12px;
}
.card_evolution .svgStarEvaluation {
  width: 25px !important;
}
.card_evolution .svgRetangleEvaluation {
  width: 100px !important;
  height: 15px !important;
}
.card_evolution .svg_active {
  fill: var(--nav1TextColor2);
  opacity: 0.8;
}

.card_evolution .svg_inactiveBorder {
  fill-opacity: 0.1;
  fill: var(--nav1TextColor2);
  stroke: var(--nav1TextColor2);
}

.card_evolution .svg_inactive {
  opacity: 0.3;
  fill: var(--nav1TextColor2);
}

.card_evolution .svg_inactive,
.card_evolution .svg_active,
.card_evolution .svg_inactiveBorder {
  height: 15px !important;
}

.card_evolution .svgRetangleEvaluationLeft .svg_inactive,
.card_evolution .svgRetangleEvaluationLeft .svg_active,
.card_evolution .svgRetangleEvaluationLeft .svg_inactiveBorder {
  height: 15px !important;
}

.card_evolution .svgRetangleEvaluationLeft {
  width: 70% !important;
  height: 15px !important;
  margin-bottom: 1px;
}
.card_evolution .svgStarEvaluationLeft {
  width: 25px !important;
  margin-bottom: 8px !important;
}
.card_evolution .mt-1 {
  margin: 0px !important;
  margin-top: 1.2px !important;
}
</style>
<style scoped>
.card_evolution.active {
  outline: 4px solid var(--cp);
}
.card_evolution {
  cursor: pointer;
  padding: 10px 5px;
  background-color: antiquewhite;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  min-height: 100%;
}
.form-check {
  overflow: hidden;
}
</style>
