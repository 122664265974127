import { render } from "./Curriculum.html?vue&type=template&id=a04fdb48&scoped=true"
import script from "./Curriculum.js?vue&type=script&lang=js"
export * from "./Curriculum.js?vue&type=script&lang=js"

import "./Curriculum.css?vue&type=style&index=0&lang=css"
import "./Curriculum.css?vue&type=style&index=1&lang=css"
import "./CurriculumScoped.css?vue&type=style&index=2&id=a04fdb48&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-a04fdb48"

export default script