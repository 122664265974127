<template>
  <div>
    <div class="d-flex justify-content-end mb-2">
      <button
        @click="$emit('openModalConfigs', 'Other')"
        type="button"
        class="btn ms-2 btn-sm btnIcon"
      >
        <img src="@/assets/img/config.svg" alt="" />
      </button>
    </div>
    <Container
      @drop="onDrop($event)"
      non-drag-area-selector=".form-control"
      drag-class="form-ghost"
      drop-class="form-ghost-drop"
      :class="{ isActive: true }"
    >
      <Draggable
        v-for="(education, index) in userEducation"
        :index="index"
        :key="education"
      >
        <Education
          :index="index"
          :key="education"
          :length="userEducation.length"
          @remove="removeEducation"
          :userEducation="education"
        ></Education>
      </Draggable>
    </Container>
    <NavAddRemoveBtn @add="addEducation" />
  </div>
</template>

<script>
import NavAddRemoveBtn from "@/components/subComponrnts/NavAddRemoveBtn";
import Education from "@/components/subComponrnts/Education";
import { Container, Draggable } from "vue-dndrop";
export default {
  name: "CardEducation",
  props: ["userEducation"],
  components: {
    Draggable,
    Container,
    Education,
    NavAddRemoveBtn,
  },
  data: function() {
    return {
      education: this.userEducation,
    };
  },

  methods: {
    onDrop(dropResult) {
      this.$emit("onDropEducation", dropResult);
    },
    addEducation() {
      this.$emit("addEducation");
    },
    removeEducation(index) {
      this.$emit("removeEducation", index);
    },
  },
};
</script>
