<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row g-2">
        <div class="col-md-6 col-lg-5 col-xl-4">
          <AutoComplete
            v-model="softSkill.skill"
            title="soft_skill"
            :datas="skills"
            :exists="skillsArr"
          />
        </div>
        <div
          class="
            col-md-6 col-lg-4 col-xl-3
            ps-2
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <star-rating
            v-if="config.soft_skills_tipo == 1"
            :inactive-color="config.soft_skills_inactive_color"
            :active-color="config.soft_skills_active_color"
            v-model:rating="softSkill.level"
            :increment="1"
            :show-rating="false"
          ></star-rating>

          <star-rating
            tp="2"
            v-else-if="config.soft_skills_tipo == 3"
            :inactive-color="config.soft_skills_inactive_color"
            :active-color="config.soft_skills_active_color"
            v-model:rating="softSkill.level"
            :increment="1"
            :show-rating="false"
          ></star-rating>

          <vue3-slider
            class="vue3-slider-2"
            v-else-if="config.soft_skills_tipo == 4"
            height="30"
            max="5"
            v-model="softSkill.level"
            :color="config.soft_skills_active_color"
            :track-color="config.soft_skills_inactive_color"
          />

          <vue3-slider
            v-else-if="config.soft_skills_tipo == 2"
            height="30"
            max="5"
            v-model="softSkill.level"
            :color="config.soft_skills_active_color"
            :track-color="config.soft_skills_inactive_color"
          />

          <star-rating
            v-else
            readOnly="true"
  
            rating="0"
            :increment="1"
            :show-rating="false"
          ></star-rating>

          <img
            v-if="length > 1"
            @click="remove"
            class="btnIconRemove ms-3"
            src="@/assets/img/delete.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarRating from "@/components/star/star-rating";
import slider from "vue3-slider";
import AutoComplete from "@/components/input/AutoComplete";

export default {
  name: "SoftSkills",
  props: ["config", "userSoftSkill", "length", "index", "softSkills"],
  components: {
    AutoComplete,
    StarRating,
    "vue3-slider": slider,
  },
  mounted: function () {
    this.root = document.documentElement;

    setTimeout(() => {
      this.skillsJson = require("@/data/soft_skills.json");
    }, 2000);
  },
  computed: {
    skillsArr: function () {
      return this.softSkills.map((d) => d.skill);
    },

    skills: function () {
      let langName;
      if (this.$i18n.locale == "pt_PT") langName = "pt_BR";
      else langName = this.$i18n.locale;
      return this.skillsJson?.[langName] ? this.skillsJson[langName] : null;
    },
  },
  methods: {
    remove() {
      this.$emit("remove", this.index);
    },
  },

  data() {
    return {
      skillsJson: null,
      softSkill: this.userSoftSkill,
    };
  },
};
</script>
