
<template>
  <div class="main_templates mb-2" ref="card">
    <div class="card-t d-flex justify-content-center">
      <div class="page row">
        <NavTop
          :configColor="configColor"
          v-if="configColor.modeloStyle == 2"
        />

        <div
          class="row"
          style="margin: 0px; padding: 0px"
          :style="{
            height: configColor.modeloStyle == 2 ? 'calc(100% - 60px)' : '100%',
            marginTop: configColor.modeloStyle == 2 ? '-7px' : '0px;',
          }"
        >
           <Nav1 @openModalConfigs="openModalConfigs" 
            :configColor="configColor"
            v-if="configColor.positionStyle == '1'"
          />

           <Nav2 @openModalConfigs="openModalConfigs"  @listExperienceClick="listExperienceClick" :style="1" :configColor="configColor" v-else />

           <Nav2 @openModalConfigs="openModalConfigs"  @listExperienceClick="listExperienceClick" 
            :configColor="configColor"
            v-if="configColor.positionStyle == '1'"
          />

           <Nav1 @openModalConfigs="openModalConfigs"   :configColor="configColor" v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav1 from "@/components/templates/T1/parts/nav1/Nav1";
import Nav2 from "@/components/templates/T1/parts/nav2/Nav2";
import NavTop from "@/components/templates/T1/parts/navTop/NavTop";

export default {
  name: "T1",
  components: {
    Nav1,
    Nav2,
    NavTop,
  },
  props: ["configColor"],
  data() {
    return {
     
     
    
    };
  },
  computed: {},
  methods: {

   listExperienceClick() {
      this.$emit("listExperienceClick");
    },
     openModalConfigs(index) {
      this.$emit("openModalConfigs",index);
    },
    set(newVal) {
      let border_radius = newVal.border_radius * 3;
      let nav1width = "85px";
      let nav1height = "280px";

      let nav2width = "113px";
      let nav2height = "280px";

      if (newVal.navPadding != 1) {
        //with out  space
        this.$refs.card.style.setProperty("--navMarginLeft", "0px");
        this.$refs.card.style.setProperty("--navMarginRight", "0px");
        this.$refs.card.style.setProperty("--navPaddingTop", "0px");

        if (newVal.positionStyle == 1 && newVal.style < 3)
          this.$refs.card.style.setProperty(
            "--border_radius",
            "0px " + border_radius + "px 0px 0px"
          );
        else if (newVal.positionStyle == 2 && newVal.style >= 3)
          this.$refs.card.style.setProperty(
            "--border_radius",
            "0px " + border_radius + "px " + border_radius + "px 0px"
          );
        else
         {
            if(newVal.style==1) this.$refs.card.style.setProperty(
            "--border_radius",
            "0px " + border_radius + "px " + border_radius + "px 0px"
          ); 
         
          else this.$refs.card.style.setProperty(
            "--border_radius",
            border_radius + "px 0px  0px " + border_radius + "px"
          );
         }

        if (
          newVal.modeloStyle == 2 &&
          (newVal.style == 1 || newVal.style == 2)
        ) {
          if (newVal.positionStyle == 1)
            this.$refs.card.style.setProperty(
              "--border_radius",
              " 0px  0px   " + border_radius + "px 0px"
            );
          else
            this.$refs.card.style.setProperty(
              "--border_radius",
              " 0px  0px 0px  " + border_radius + "px"
            );
        } else if (
          newVal.modeloStyle == 2 &&
          (newVal.style == 3 || newVal.style == 4)
        ) {
          if (newVal.positionStyle == 1) {
            this.$refs.card.style.setProperty("--border_radius", "0px");
            this.$refs.card.style.setProperty(
              "--rightBorderRadius",
              "0px " + border_radius + "px " + 0 + "px 0px "
            );
          } else {
            this.$refs.card.style.setProperty("--border_radius", "0px");
            this.$refs.card.style.setProperty(
              "--rightBorderRadius",
              border_radius + "px 0px 0px 0px  "
            );
          }
        }
      }
      //with space
      else {
        if (newVal.positionStyle == 1) {
          // L->R
          if (newVal.modeloStyle == 2)
            //T RL
            this.$refs.card.style.setProperty(
              "--navMarginLeft",
              "4px 0px 4px 0px"
            );
          //LR
          else
            this.$refs.card.style.setProperty(
              "--navMarginLeft",
              "7px  0px 7px 7px"
            );

          this.$refs.card.style.setProperty(
            "--navMarginRight",
            "7px 7px 7px 0px"
          );
          this.$refs.card.style.setProperty(
            "--navPaddingTop",
            " 4px 4px 4px 0px"
          );
        } else {
          //R->L    //with space
          this.$refs.card.style.setProperty(
            "--navPaddingTop",
            " 4px 0px 4px 4px"
          );

          if (newVal.modeloStyle == 2)
            this.$refs.card.style.setProperty(
              "--navMarginLeft",
              "4px 0px 4px 0px"
            );
          else
            this.$refs.card.style.setProperty(
              "--navMarginLeft",
              "7px  7px 7px 0px"
            );

          this.$refs.card.style.setProperty(
            "--navMarginRight",
            "7px 0px 7px 7px"
          );
        }

        this.$refs.card.style.setProperty(
          "--border_radius",
          border_radius + "px"
        );
        if (
          newVal.modeloStyle == 2 &&
          (newVal.style == 3 || newVal.style == 4)
        ) {
          this.$refs.card.style.setProperty("--border_radius", "0px");

          if (newVal.positionStyle == 1)
            this.$refs.card.style.setProperty(
              "--rightBorderRadius",
              "0px " + border_radius + "px " + border_radius + "px 0px "
            );
          else
            this.$refs.card.style.setProperty(
              "--rightBorderRadius",
              border_radius + "px 0px 0px " + border_radius + "px"
            );
        }

        if (
          newVal.modeloStyle == 2 &&
          (newVal.style == 1 || newVal.style == 2)
        )
          if (newVal.positionStyle == 1)
            this.$refs.card.style.setProperty(
              "--border_radius",
              "0px " + border_radius + "px  " + border_radius + "px 0px "
            );
          else
            this.$refs.card.style.setProperty(
              "--border_radius",
              +border_radius + "px 0px 0px " + border_radius + "px  "
            );

        nav1width = "78px";

        nav2width = "106px";

        if (newVal.modeloStyle == 1) {
          //LR
          nav2height = "266px";
          nav1height = "266px";
        }
      } //with space

      //start  modelo 2 Top LR
      if (newVal.modeloStyle == 2) {
        //TLR

        this.$refs.card.style.setProperty(
          "--navMarginRight",
          "7px 0px 7px 7px"
        );

        this.$refs.card.style.setProperty(
          "--navMarginLeft",
          "7px  7px 7px 0px"
        );

        let border_radiusTop = "0px 0px " + border_radius + "px   0px  ";
        let navTopHeight = "65px";
        let navTopWidth = "198px";
        let navTopMargin = "0px";

        if (newVal.positionStyle == 2) {
          //R->L
          border_radiusTop = "0px 0px 0px  " + border_radius + "px";
        }

        if (newVal.navPadding == 1) {
          //with space

          navTopHeight = "58px";
          navTopWidth = "191px";

          if (newVal.positionStyle == 2) {
            //R->L
            border_radiusTop =
              border_radius + "px  0px 0px  " + border_radius + "px";
            navTopMargin = "7px 0px  0px 7px";
          } else {
            //L->R
            border_radiusTop =
              "0px " + border_radius + "px " + border_radius + "px 0px";
            navTopMargin = "7px 7px  0px 0px";
          }
        
        }

        this.$refs.card.style.setProperty("--border_radius", border_radiusTop);

        this.$refs.card.style.setProperty("--navTopMargin", navTopMargin);
        this.$refs.card.style.setProperty("--navTopWidth", navTopWidth);
        this.$refs.card.style.setProperty("--navTopHeight", navTopHeight);

          if (newVal.style == 4) {
          
            nav2height = "206px";
            nav1height = "206px";
            
            // nav2width = "113px";
      nav1width = "85px";
        
        

let navMarginRight =  "7px 7px 7px 0px";

 let rightBorderRadius=" 0px "+ border_radius + "px " + border_radius + "px 0px ";

  if (newVal.positionStyle == 2) {//R->L
          rightBorderRadius=border_radius + "px 0px 0px " + border_radius + "px";
         navMarginRight =  "7px 0px 7px 7px";

         }

  this.$refs.card.style.setProperty(
        "--navMarginRight",navMarginRight
        );
  this.$refs.card.style.setProperty(
              "--rightBorderRadius", rightBorderRadius
            );


 this.$refs.card.style.setProperty(
          "--navMarginLeft",
          "7px 0px 7px 0px"
        );
             this.$refs.card.style.setProperty(
              "--border_radius",
             "0px"
            );
       
          }

       
        


      }

      //fim modelo 2 Top LR

      this.$refs.card.style.setProperty("--navPaddingTop", " 9px 9px 0px 9px");

      this.$refs.card.style.setProperty("--nav1width", nav1width);
      this.$refs.card.style.setProperty("--nav1height", nav1height);

      this.$refs.card.style.setProperty("--nav2width", nav2width);
      this.$refs.card.style.setProperty("--nav2height", nav2height);

      if (newVal.positionStyle == 1) {
        this.$refs.card.style.setProperty("--nav1Padding", "13px 3px 9px 9px");
        this.$refs.card.style.setProperty("--nav2Padding", "13px 9px 9px 3px");
      } else {
        this.$refs.card.style.setProperty("--nav1Padding", "13px 9px 9px 3px");
        this.$refs.card.style.setProperty("--nav2Padding", "13px 3px 9px 9px");
      }

      let nav1BgColor,
        nav1BgColor1,
        nav1BgColor2,
        nav1TextColor1,
        nav1TextColor2,
        nav2TextColor1,
        nav2TextColor2 = null;

      nav1BgColor1 = newVal.p_bg;
      nav1BgColor2 = newVal.s_bg;

      nav1TextColor1 = newVal.p_color;
      nav1TextColor2 = newVal.s_color;
      nav2TextColor1 = newVal.nav2TextColor1;
      nav2TextColor2 = newVal.nav2TextColor2;

      if (newVal.positionStyle == 2 && newVal.modeloStyle == 1) {
        nav1TextColor1 = newVal.nav2TextColor1;
        nav1TextColor2 = newVal.nav2TextColor2;
        nav2TextColor1 = newVal.p_color;
        nav2TextColor2 = newVal.s_color;

        nav1BgColor1 = newVal.s_bg;
        nav1BgColor2 = newVal.p_bg;
      }

      if (newVal.modeloStyle == 2) {
        nav1TextColor1 = nav2TextColor1 = newVal.nav2TextColor1;
        nav1TextColor2 = nav2TextColor2 = newVal.nav2TextColor2;
      }

      switch (parseInt(newVal.style)) {
        case 1:
          nav1BgColor = newVal.s_bg;

          if (newVal.modeloStyle == 2) {
            this.$refs.card.style.setProperty(
              "--navTopTextColor",
              newVal.p_color
            );
            this.$refs.card.style.setProperty(
              "--navTopTextColor1",
              newVal.s_color
            );
          }

          break;

        case 4:
          nav1BgColor = newVal.p_bg;

          if (newVal.modeloStyle == 2) {
            this.$refs.card.style.setProperty(
              "--navTopTextColor",
              newVal.p_color
            );
            this.$refs.card.style.setProperty(
              "--navTopTextColor1",
              newVal.s_color
            );
          }
          break;
        default:
          break;
      }

      if (newVal.modeloStyle == 2) {
        this.$refs.card.style.setProperty("--navTopBgColor", nav1BgColor1);
        nav1BgColor = nav1BgColor1;

        if (newVal.style == 3 || newVal.style == 4) {
          nav1BgColor = nav1BgColor1;
          nav1BgColor1 = nav1BgColor2;
        } else nav1BgColor1 = nav1BgColor = nav1BgColor2;
      }

      this.$refs.card.style.setProperty("--nav1BgColor", nav1BgColor);
      this.$refs.card.style.setProperty("--nav1BgColor1", nav1BgColor1);
      this.$refs.card.style.setProperty("--nav1BgColor2", nav1BgColor2);

      this.$refs.card.style.setProperty("--nav1TextColor1", nav1TextColor1);
      this.$refs.card.style.setProperty("--nav1TextColor2", nav1TextColor2);

      this.$refs.card.style.setProperty("--nav2TextColor1", nav2TextColor1);
      this.$refs.card.style.setProperty("--nav2TextColor2", nav2TextColor2);
      
  this.$refs.card.style.setProperty("--hrWidth", newVal.hr_width+'%');
      
    },
  },

  mounted: function () {
    this.set(this.configColor);
  },

  watch: {
    configColor: {
      deep: true,
      handler: function (newVal) {
        this.set(newVal);
      },
    },
  },
};
</script>

<style >
.main_templates .cl{
  cursor: pointer;
}
.main_templates .cl:hover{
  opacity: 0.85;
}
.svgRetangleEvaluationLeft{
      width: 48px;
      height: 5px;
      margin-bottom: 1px;
}
.svgRetangleEvaluationLeft .svg_inactive,
.svgRetangleEvaluationLeft .svg_active,
.svgRetangleEvaluationLeft  .svg_inactiveBorder{
   height: 5px;
}
.svgStarEvaluationLeft{
  width: 6.3px;
    margin-bottom: 1.3px;
}

.tj{
   text-align: justify;
}
.page {
  overflow: hidden;
  outline: 1px solid var(--cp);
}

.main_templates {
  --navTopTextColor1: #de1b1b;
  --navTopTextColor: #de1b1b;
  --navTopBgColor: #de1b1b;
  --nav1BgColor: #de1b1b;
  --nav1BgColor1: #5f5f5f;
  --nav1BgColor2: #ffffff;

  --nav1TextColor2: #111111;
  --nav1TextColor1: #111111;

  --pageC_o: #111111;

  --nav2TextColor1: #111111;
  --nav2TextColor2: #111111;

  --border_radius: 7px;
  --navMarginLeft: 7px;
  --navMarginRight: 7px;

  --navPaddingTop: 0px;
  --rightBorderRadius: 0px 15px 15px 0px;

  --nav1BgColor1_padding: none;
  --nav1width: 85px;
  --nav1height: 266px;

  --nav1Padding: 13px 3px 9px 9px;
  --nav2Padding: 13px 9px 9px 3px;

  --nav2height: 280px;
  --nav2width: 113px;
  --hrWidth:0%;
}
.strong {
  font-weight: bold;
}
.page {
  /*box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;;*/
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  background-color: var(--nav1BgColor);
  /*filter: blur(0.1px);*/
  font-size: 5px;
  line-height: 1.1;
  height: 280px;
  width: 198px;
  margin: 0px;

  min-width: 198px;
  min-height: 280px;
}
.card-t {
  width: 100%;
}
.imgUSer {
  padding: 15px;
  padding-top: 4px;
  padding-bottom: 3px;
}
.imgUSer.circle {
  border-radius: 50%;
}
.ico_info {
  margin-right: 1.5px;
  margin-top: 0px;
  width: 7px;
  height: 5px;
  vertical-align: middle;
  float: left;
  color: var(--nav1TextColor1);
  background-color: var(--nav1TextColor1);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
.ico-2 {
  margin-right: 0px;
  width: 3px;
  vertical-align: middle;
}

.t1-p1-Top {
  background-color: var(--nav1BgColor1_padding);
  height: 67px;
}

.t1-p1 .svg_active {
  fill: var(--nav1TextColor2);
  opacity: 0.8;
}
.t1-p1 .svg_inactive {
  opacity: 0.3;
  fill: var(--nav1TextColor2);
}
.t1-p1 .svg_inactiveBorder {
 fill-opacity: 0.1;
  fill: var(--nav1TextColor2);
  stroke: var(--nav1TextColor2);
}


.t1-p2 .svg_active {
  fill: var(--nav2TextColor2);
  opacity: 0.8;
}
.t1-p2 .svg_inactive {
  opacity: 0.3;
  fill: var(--nav2TextColor2);
}

.t1-p2 .svg_inactiveBorder {

   fill-opacity: 0.1;
 fill: var(--nav2TextColor2);
  stroke: var(--nav2TextColor2);
}

.t1-p1 h1,
.t1-p1 h2 .t1-p1 .title{
  color: var(--nav1TextColor1);
}
.t1-p1 {
  z-index: 0;
  width: 85px;
  color: var(--nav1TextColor2);
  position: relative;
  padding: var(--nav1Padding);
}
.t1-p1-nav {
  z-index: -1;

  background-color: var(--nav1BgColor1);
  border-radius: var(--border_radius);
  position: absolute;
  top: 0px;
  left: 0;
  width: var(--nav1width);
  height: var(--nav1height);
  margin: var(--navMarginLeft);
}
.t1-p1 .badge {
  color: var(--nav1BgColor1);
  background-color: var(--nav1TextColor2);
  margin-right: 1.2px;
  margin-top: 1.2px;
}
.rightBorderRadius {
  border-radius: var(--rightBorderRadius) !important;
}

.t1-p2 h1,
.t1-p2 h2  , .t1-p2 .title{
  color: var(--nav2TextColor1);
}
.t1-p2 {
  width: 113px;
  z-index: 0;
  color: var(--nav2TextColor2);
  position: relative;

  padding: var(--nav2Padding);
}

.t1-p2  .badge {
  color:  var(--nav1BgColor2);
  background-color: var(--nav2TextColor2);
  margin-right: 1.2px;
  margin-top: 1.2px;
}

.t1-p2-nav {
  background-color: var(--nav1BgColor2);
  border-radius: var(--border_radius);
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0;
  height: var(--nav2height);
  width: var(--nav2width);
  margin: var(--navMarginRight);
}
hr {
  margin: 0.3px;
  margin-top: -7px !important;
  height: 0.5px;
  border: 0;
  opacity: 1;
  width:var(--hrWidth);
}

.t1-p2 hr {
 
  color: var(--nav2TextColor1);
}

.t1-p1 hr {
 
  background-color: var(--nav1TextColor1);
}

.main_templates .mt-5 {
  margin-top: 7px !important;
}
.main_templates .mt-1 {
  margin: 0px !important;
  margin-top: 1.2px !important;
}
.main_templates .me-1 {
  margin-right: 1px !important;
}
.main_templates .ms-1 {
  margin-left: 1px !important;
}
.main_templates .mt-2 {
  margin: 0px !important;
  margin-top: 2.4px !important;
}

.main_templates .tc {
  text-align: center;
}
.main_templates p {
  margin-bottom: 4px;
}

.main_templates h1 {
  font-size: 6px;
  margin: 0.2;
  font-weight: 2px;
}
.main_templates h2 {
  font-size: 5px;
  margin: 0.2;
  font-weight: 1px;
}
.main_templates h1,
.main_templates h2,
.main_templates h3,
.main_templates h4,
.main_templates h5 {
  text-transform: unset;
}

.icoUser {
  -webkit-mask-image: url("~@/assets/img/user_1.svg");
  mask-image: url("~@/assets/img/user_1.svg");
}
.icoCalender {
  -webkit-mask-image: url("~@/assets/img/calender.svg");
  mask-image: url("~@/assets/img/calender.svg");
}
.icoHome {
  -webkit-mask-image: url("~@/assets/img/home.svg");
  mask-image: url("~@/assets/img/home.svg");
}
.icoLinkedIn {
  -webkit-mask-image: url("~@/assets/img/LinkedIn.svg");
  mask-image: url("~@/assets/img/LinkedIn.svg");
}
.icoFone {
  -webkit-mask-image: url("~@/assets/img/fone.svg");
  mask-image: url("~@/assets/img/fone.svg");
}
.icoEmail {
  -webkit-mask-image: url("~@/assets/img/email.svg");
  mask-image: url("~@/assets/img/email.svg");
}

.paddingLeftZerro {
  padding-left: 0px !important;
}
.paddingRightZerro {
  padding-right: 0px !important;
}
</style>

