<template>

<div class="d-flex justify-content-end mb-2">
      <button
        @click="$emit('openModalConfigs', 'Other')"
        type="button"
        class="btn ms-2 btn-sm btnIcon"
      >
        <img src="@/assets/img/config.svg" alt="" />
      </button>
    </div>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row g-2">
        <div class="col-md-6 col-lg-4 col-xl-3">
          <div class="form-floating">
            <input
              v-model="userData.drivingLicence"
              type="text"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInputGrid">{{
              $t("labels.driving_licence")
            }}</label>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 col-xl-3">
          <div class="form-floating mb-3">
            <input
              v-model="userData.date_birth"
              type="date"
              class="form-control"
            />

            <label for="floatingInput">{{ $t("labels.date_of_birth") }}</label>
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3">
          <OtherOp
            keyName="gender"
            v-model="userData.gender"
            title="gender"
            :datas="pageData.genders"
          />
        </div>

        <div class="col-md-6 col-lg-4 col-xl-3">
          <Countrie
            keyName="nationality"
            v-model="userData.nationality"
            title="nationality"
            :countries="pageData.countries"
          />
        </div>

        <div class="col-md-6 col-lg-4 col-xl-6">
          <div class="form-floating mb-3">
            <input
              v-model="userData.linkedin"
              type="text"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInput">{{ $t("labels.linkedin") }}</label>
          </div>
        </div>

        <div class="col-md-6 col-lg-4 col-xl-6">
          <div class="form-floating mb-3">
            <input
              v-model="userData.portfolio"
              type="text"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInput">{{ $t("labels.portfolio") }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-2">
    <div class="card-body">
      <div class="row g-2">
        <div class="col-12">
        <inputTags
            :datas="hobbiesDataList"
            :title="userDatas.config.hobbies_title"
            v-model="userData.hobbies"
          ></inputTags>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OtherOp from "@/components/input/OtherOp";
import Countrie from "@/components/input/Countrie";
import inputTags from "@/components/input/inputTags";

export default {
  name: "CardOther",

  props: ["userDatas", "pageData","curriculumVersionID"],
  components: {
    Countrie,
    OtherOp,
    inputTags,
  },

  data: function () {
    return {
      userData: this.userDatas,
      hobbiesjson: null,
    };
  },
  methods: {},


  
  watch: {
    curriculumVersionID: {
      deep: true,
      handler: function () {
       this.userData= this.userDatas
      },
    },
    userData: {
      deep: true,
      handler: function (newVal) {
        this.$emit("updateOtherInfo", newVal);
      },
    },
  },
  computed: {
    hobbiesDataList: function () {
      let langName;
      if (this.$i18n.locale == "pt_PT") langName = "pt_BR";
      else langName = this.$i18n.locale;

      return this.hobbiesjson?.[langName] ? this.hobbiesjson[langName] : null;
    },
  },
  mounted() {
    setTimeout(() => {
      this.hobbiesjson = require("@/data/hobbies.json");
    }, 1000);
  },
};
</script>



