<template>
  <div class="form-floating mb-3">
    <div class="dial_code floating" @click="show = !show">
      <img class="flags" v-if="selected" :src="flagSrc" :alt="selected" />
      <span class="spanText" style="">{{ selectedText }}</span>
    </div>

    <input type="text" class="form-control inputNumber" placeholder=" " />
    <transition name="fade">
      <ul
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        v-if="show"
        class="list-unstyled ulAuto"
      >
        <li class="list-unstyled sgID liAuto">
          <input
           
            ref="searchTextInput"
            @focusout="focusout"
            :value="searchText"
            @input="(evt) => (searchText = evt.target.value)"
            class="form-control form-control-sm search"
            type="text"
            placeholder="Search"
            aria-label="input search "
          />
        </li>

        <li
          @click="selectOp('', $t('labels.preferNotSay' ))"
          class="list-unstyled sgID liAuto"
          v-if="searchText==''"
        >
        
          {{ $t("labels.preferNotSay" ) }}
        </li>

        <li
          v-for="countrie in filtredCountries"
          :key="countrie.id"
          @click="selectOp(countrie.id, countrie[this.dataLang])"
          class="list-unstyled sgID liAuto"
        >
          <img
            class="flags"
            :src="require(`@/assets/img/flags/${countrie.id}.png`)"
            :alt="countrie[this.dataLang]"
          />

          {{ countrie[this.dataLang] }}
        </li>
      </ul>
    </transition>
    <label for="Phone">{{ $t("labels." + title) }}</label>
  </div>
</template>

<script>
export default {
  name: "Countrie",
  mounted() {
    this.selectedText = this.getContryNameById(this.selected);
  },
  computed: {
    dataLang: function () {
      if (this.$i18n.locale == "pt_PT") return this.keyName + "_pt_BR";
      return this.keyName + "_" + this.$i18n.locale;
    },
    flagSrc: function () {
      try {
        return require(`@/assets/img/flags/${this.selected}.png`);
      } catch (error) {
        return require(`@/assets/img/flags/cv.png`);
      }
    },
    filtredCountries: function () {
      return ( this.countries || []).filter((countrie) => {
        if (this.searchText.trim() != "") {
          return countrie[this.dataLang]
            .toLowerCase()
            .includes(this.searchText.trim().toLowerCase());
        } else
          return (
            countrie[this.dataLang] != null && countrie[this.dataLang] != ""
          );
      });
    },
  },

  data() {
    return {
      searchText: "",

      selected: this.modelValue,
      selectedText: "",
      show: false,
      hover: false,
    };
  },

  props: {
    keyName: {
      default: "nationality",
    },

    modelValue: {
      default: "cv",
    },

    countries: {
      default: [],
    },
    title: {
      default: "",
    },
  },
  watch: {
    show: {
      deep: true,
      handler: function (neww) {
        if (neww == true) {
          setTimeout(() => {
            try {
              this.$refs.searchTextInput.focus();
            } catch (e) {
              console.log(e);
            }
          }, 100);
        }
      },
    },
    countries: {
      deep: true,
      handler: function () {
        this.selectedText = this.getContryNameById(this.selected);
      },
    },

    selected: {
      deep: true,
      handler: function (newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    modelValue: {
      deep: true,
      handler: function (newVal) {
        this.selected = newVal;
        this.selectedText = this.getContryNameById(newVal);
      },
    },
  },

  methods: {
    getContryNameById(id) {
      if(id==null ||id=='') return this.$t('labels.preferNotSay');
      let str = this.countries.find((countrie) => countrie.id === id);
      if ((str ? str.length : 0) < 1) return "";

      return str[this.dataLang];
    },
    selectOp(id, text) {
    this.selectedText = text;
      this.selected = id;

      this.hover = this.show = false;
      this.searchText = "";
    },
    focusout() {
      if (this.hover) return;
      this.hover = this.show = false;
      this.searchText = "";
    },
  },
};
</script>

<style scoped>
.form-floating {
  white-space: unset;
  overflow: unset;
}
.form-floating label {
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
input.search {
  margin-top: 2.5px;
  margin-bottom: 2px;
}
.dial_code.floating {
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  top: 22px;
  left: 11px;
  cursor: pointer;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  width: calc(100% - 11px);

  padding: 2px 2px;
}

.form-floating .inputNumber {
  pointer-events: none;
}
.spanText {
  display: inline-block;
  overflow: hidden;
  width: calc(100% - 53px);
  margin-left: 2px;
}
.flags {
  vertical-align: unset;
  width: 24px;
  height: 24px;
  margin-right: 2.2px;
}
.list-unstyled .flags {
  vertical-align: text-bottom;
}
.ulAuto {
  background-color: #fff;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  width: 100%;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  max-height: 200px;
  overflow-y: auto;
}
.liAuto {
  padding: 2px 12px;
}
.liAuto:hover {
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-floating > label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
@media (max-width: 768px) {

  .liAuto {
  padding: 4px 12px;
}
}
</style>