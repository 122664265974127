<template>
  <div>
     <div class="d-flex justify-content-end mb-2">
      <button
        @click="$emit('openModalConfigs', 'soft')"
        type="button"
        class="btn ms-2 btn-sm btnIcon"
      >
        <img src="@/assets/img/config.svg" alt="" />
      </button>
    </div>
    <Container
      @drop="onDrop($event)"
      non-drag-area-selector=".form-control"
      drag-class="form-ghost"
      drop-class="form-ghost-drop"
      :class="{ isActive: true }"
    >
      <Draggable
      v-for="(softSkill, index) in softSkills"
      :key="softSkill"
      :index="index"
      >
    <SoftSkills
      :softSkills="softSkills"
      :config="config"
     
      :key="softSkill"
      :index="index"
      :userSoftSkill="softSkill"
      @remove="removeSoftSkill"
      :length="softSkills.length"
    >
    </SoftSkills>
  </Draggable>
    </Container>
    <NavAddRemoveBtn @add="addSoftSkill" />
  </div>
</template>
<script>
import SoftSkills from "@/components/subComponrnts/SoftSkills";

import NavAddRemoveBtn from "@/components/subComponrnts/NavAddRemoveBtn";
import { Container, Draggable } from "vue-dndrop";
export default {
  name: "CardSoftSkills",
  props: ["config", "softSkills"],
  components: {
    Draggable,
    Container,
    SoftSkills,
  
    NavAddRemoveBtn,
  },
  methods: {
    onDrop(dropResult) {
      this.$emit("onDropSoftSkill", dropResult);
    },
    addSoftSkill() {
      this.$emit("addSoftSkill");
    },
    removeSoftSkill(index) {
      this.$emit("removeSoftSkill", index);
    },
  },

  data() {
    return {};
  },
};
</script>
