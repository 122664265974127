<template>
  <div class="d-flex justify-content-end mb-2">
    <button
      @click="$emit('openModalConfigs', 'language')"
      type="button"
      class="btn ms-2 btn-sm btnIcon"
    >
      <img src="@/assets/img/config.svg" alt="" />
    </button>
  </div>
  <Container
    @drop="onDrop($event)"
    non-drag-area-selector=".form-control"
    drag-class="form-ghost"
    drop-class="form-ghost-drop"
    :class="{ isActive: true }"
  >
    <Draggable
      v-for="(language, index) in userLanguage"
      :key="language"
      :index="index"
    >
      <Language
        :config="config"
        :userLanguages="userLanguage"
        :key="language"
        :index="index"
        :userLanguage="language"
        @remove="removeLanguage"
        :length="userLanguage.length"
      ></Language>
    </Draggable>
  </Container>
  <NavAddRemoveBtn @add="addLanguage" />
</template>
<script>
import Language from "@/components/subComponrnts/Language";

import NavAddRemoveBtn from "@/components/subComponrnts/NavAddRemoveBtn";
import { Container, Draggable } from "vue-dndrop";
export default {
  name: "CardLanguage",

  components: {
    Language,
    Draggable,
    Container,
    NavAddRemoveBtn,
  },
  props: ["config", "userLanguage", "length", "index"],
  data: function() {
    return {
      language: this.userLanguage,
    };
  },
  methods: {
    onDrop(dropResult) {
      this.$emit("onDropLanguage", dropResult);
    },

    addLanguage() {
      this.$emit("addLanguage");
    },
    removeLanguage(index) {
      this.$emit("removeLanguage", index);
    },
  },
};
</script>
