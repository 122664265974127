<template>
  <div class="card mb-2">
    <div class="card-body">
      <div class="row g-3">
        <div class="col-md-4  col-xl-4">
          <div class="form-floating">
            <input
              v-model="certification.title"
              type="text"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInput"
              >{{ $t("labels.title_of_qualification") }} <span>*</span></label
            >
          </div>
        </div>
        <div class="col-sm-8 col-md-4  col-xl-4">
          <div class="form-floating">
            <input
              v-model="certification.organization"
              type="text"
              class="form-control"
              placeholder=" "
            />
            <label for="floatingInput">{{ $t("labels.organization") }}</label>
          </div>
        </div>

        <div class="col-sm-4 col-md-3 col-lg-2 col-xl-2">
          <div class="form-floating">
            <select
              v-model="toDt"
              :disabled="certification.ongoing"
              class="form-select"
              aria-label="Floating label select example"
            >
              <option value=""></option>
              <option v-for="n in 40" :key="n" :value="maxYear - n">{{
                maxYear - n
              }}</option>
            </select>
            <label for="floatingSelect">{{ $t("labels.to_date") }}</label>
            <div class="form-check">
              <input
                v-model="certification.ongoing"
                class="form-check-input"
                type="checkbox"
              />
              <label class="form-check-label"
                >{{ $t("labels.ongoing") }}
              </label>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-floating form-textarea">
            <textarea
              style="height: 100px"
              v-model="certification.description"
              class="form-control"
              placeholder=" "
            ></textarea>
            <label for="floatingTextarea">{{ $t("labels.description") }}</label>
          </div>
          <label class="d-flex justify-content-end">
            <img
              v-if="length > 1"
              @click="remove"
              class="btnIconRemove mt-2"
              src="@/assets/img/delete.svg"
              alt=""
          /></label>
        </div>

        <div class="col-12 col-md-auto mt-0 mt-md-4 text-end">
          <label class="">
            <img
              v-if="length > 1"
              @click="remove"
              class="btnIconRemove "
              src="@/assets/img/delete.svg"
              alt=""
          /></label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const today = new Date();

export default {
  name: "Certification",
  props: ["userCertification", "index", "length"],
  data: function() {
    return {
      certification: this.userCertification,
      maxYear: today.getFullYear() + 1,
    };
  },
  computed: {
    toDt: {
      get() {
        return this.certification.ongoing ? "" : this.certification.toDt;
      },

      set(newValue) {
        this.certification.toDt = newValue;
      },
    },
  },
  methods: {
    remove() {
      this.$emit("remove", this.index);
    },
  },
};
</script>
